import { Request } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { TCurrencyRates, THistoricalCurrencyRates } from './types';

export const loadCurrencyRatesFx = domain.createEffect(async () => {
	const response = await Request.get<TCurrencyRates>('/currency/rates/all');
	return response.data;
});

export const loadHistoryFx = domain.createEffect(async () => {
	const response = await Request.get<THistoricalCurrencyRates>('/currency/rates/all/24h');
	return response.data;
});
