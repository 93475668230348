import * as React from 'react';

const SvgTelegram = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
		<path
			stroke="#707290"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.667}
			d="M8.75 11.25 17.5 2.5m-8.644 9.023 2.19 5.632c.193.496.29.744.429.817.12.063.264.063.384 0 .14-.072.236-.32.43-.816L17.78 3.083c.175-.448.262-.672.215-.815a.42.42 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 7.711c-.496.194-.744.29-.816.43a.42.42 0 0 0 0 .384c.072.14.32.236.817.429l5.631 2.19a1 1 0 0 1 .194.089q.056.04.097.097c.03.042.05.093.089.193"
		/>
	</svg>
);

export default SvgTelegram;
