import * as React from 'react';
import { SVGProps } from 'react';

const SvgBtc = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32" className="size-4" {...props}>
		<path
			fill="#F7931A"
			d="M32.188 19.87c-2.138 8.572-10.82 13.788-19.393 11.651-8.57-2.137-13.787-10.819-11.65-19.39S11.966-1.659 20.536.48c8.573 2.137 13.79 10.82 11.653 19.392"
		/>
		<path
			fill="#fff"
			d="M23.722 13.72c.318-2.128-1.303-3.273-3.52-4.036l.72-2.884-1.756-.438-.7 2.808a74 74 0 0 0-1.407-.33l.705-2.827-1.755-.438-.72 2.883a59 59 0 0 1-1.12-.263l.002-.01-2.421-.604-.467 1.875s1.302.299 1.275.317c.71.178.84.648.818 1.021l-.82 3.286q.076.017.183.058l-.185-.046-1.149 4.603c-.086.216-.307.54-.804.417.017.025-1.276-.319-1.276-.319l-.872 2.01 2.285.569c.425.107.841.218 1.252.323l-.727 2.917 1.754.438.72-2.887q.716.194 1.398.363l-.717 2.873 1.756.438.727-2.912c2.993.566 5.245.338 6.192-2.37.764-2.18-.038-3.437-1.613-4.257 1.147-.264 2.011-1.019 2.242-2.577m-4.012 5.625c-.542 2.18-4.213 1.002-5.403.706l.964-3.864c1.19.297 5.006.885 4.44 3.158m.543-5.656c-.495 1.983-3.55.976-4.541.729l.874-3.505c.991.247 4.183.708 3.667 2.776"
		/>
	</svg>
);

export default SvgBtc;
