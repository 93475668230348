import * as React from 'react';

const SvgChevronDown = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
		<path
			stroke="#707290"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="m4.5 6.75 4.5 4.5 4.5-4.5"
		/>
	</svg>
);

export default SvgChevronDown;
