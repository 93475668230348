import * as React from 'react';

const SvgPlus = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M10 4.166v11.667M4.165 9.999h11.667"
		/>
	</svg>
);

export default SvgPlus;
