import { createContext } from 'react';

export interface LayoutContextType {
	isSidebarOpened: boolean;
	isWalletModalOpened: boolean;
	isProfileModalOpened: boolean;
	isEarnModalOpened: boolean;
	isGeoBlockModalOpened: boolean;
	isPlayBlockModalOpened: boolean;
	isSettingsModalOpened: boolean;
	isGameMode: boolean;
	isCompactMode: boolean;
	isBalanceHidden: boolean;
	isStickyMenuHidden: boolean;
	isGameInformationViewOpened: boolean;
	toggleStickyMenu: (value: boolean) => void;
	toggleBalance: () => void;
	goBack: () => void;
	toggleSidebar: () => void;
	closeSidebar: () => void;
	enableGameMode: () => void;
	disableGameMode: () => void;
	enableCompactMode: () => void;
	disableCompactMode: () => void;
	toggleProfileModal: () => void;
	toggleEarnModal: () => void;
	toggleWalletModal: () => void;
	toggleSettingsModal: () => void;
	navigateTo: (path: string) => void;
	navigateToProfile: () => void;
	navigateToWallet: () => void;
	navigateToEarn: () => void;
	navigateToHomepage: () => void;
	navigateToRoadmap: () => void;
	navigateToPromotions: () => void;
	navigateToPromotion: (promotionSlug: string) => void;
	navigateToCasino: () => void;
	navigateToLiveCasino: () => void;
	navigateToCryptoGames: () => void;
	navigateToSportsbook: () => void;
	navigateToGame: (gameProviderSlug: string, gameSlug: string) => void;
	navigateToGameProvider: (gameProviderSlug: string) => void;
	navigateToUpAndDownTurbo: () => void;
	navigateToWhalesChoiceBanner: () => void;
	setGameInformationView: (value: boolean) => void;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export default LayoutContext;
