import { domain } from './domain';
import { Notification } from './types';

export const loadConnectionToken = domain.createEvent('loadConnectionToken');

export const initNotificationChannel = domain.createEvent<{ userId: number }>('initNotificationChannel');

export const addChannelMessage = domain.createEvent<Notification>('addChannelMessage');
export const readNotification = domain.createEvent<Notification['id']>('readNotification');
export const readAllNotifications = domain.createEvent('readAllNotifications');
export const hideNotification = domain.createEvent<Notification['id']>('hideNotification');
