import { useUnit } from 'effector-react';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import { clientOnly } from 'vike-react/clientOnly';

import {
	$$countNewNotifications,
	$$notificationsToDisplay,
	readAllNotifications,
} from '@whale-client/dataflow-notifications';
import { $userLanguage } from '@whale-client/dataflow-user';
import { $$userPlayBlock } from '@whale-client/dataflow-user';
import CurrencyMenu from '../src/components/CurrencyMenu';
import useLayoutContext from '../src/hooks/useLayoutContext';

import { Footer } from '@/components/Footer/Footer';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import Badge from '@/lib/components/Badge';
import InfoBar from '@/lib/components/InfoBar';
import NotificationCenter from '@/lib/icons/NotificationCenter';
import PlusIcon from '@/lib/icons/Plus';
import MainLayout from '@/lib/layouts/MainLayout';
import GameInformation from '@/src/components/GameInformation';

const GeoBlockModal = clientOnly(() => import('../src/components/modals/GeoBlock'));
const PlayBlockModal = clientOnly(() => import('../src/components/modals/PlayBlock'));
const StickyBottomMenu = clientOnly(() => import('../src/components/StickyBottomMenu'));
const Sidebar = clientOnly(() => import('./../src/components/Sidebar'));
const ProfileModal = clientOnly(() => import('./../src/components/modals/Profile'));
const EarnModal = clientOnly(() => import('./../src/components/modals/Earn'));
const WalletModal = clientOnly(() => import('@/src/components/modals/Wallet'));
const SettingsModal = clientOnly(() => import('@/src/components/modals/Settings'));

const Toaster = clientOnly(() => import('@/components/ui/sonner'));
const Notifications = clientOnly(() => import('@/components/Notifications/Notifications'));

const ReadTrigger = () => {
	const read = useUnit(readAllNotifications);
	useEffect(read, []);
	return null;
};

const NotificationsCenter = () => {
	const notificationsCount = useUnit($$countNewNotifications);
	const notificationsToDisplay = useUnit($$notificationsToDisplay);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					className="bg-transparent"
					size="icon"
					aria-label="Notification Center"
					disabled={notificationsToDisplay.length === 0}
				>
					{notificationsCount > 0 ? (
						<Badge>
							<Badge.Text>{notificationsCount > 99 ? '99+' : notificationsCount}</Badge.Text>
						</Badge>
					) : null}
					<NotificationCenter />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="flex flex-col gap-2">
				<ReadTrigger />
				<Notifications />
			</PopoverContent>
		</Popover>
	);
};

const Layout = ({ children }) => {
	const {
		isSidebarOpened,
		isProfileModalOpened,
		isEarnModalOpened,
		isWalletModalOpened,
		isSettingsModalOpened,
		isStickyMenuHidden,
		isGameInformationViewOpened,
		toggleSidebar,
		toggleEarnModal,
		toggleProfileModal,
		toggleWalletModal,
		navigateToHomepage,
		navigateToCasino,
		navigateToSportsbook,
		isGameMode,
		isCompactMode,
	} = useLayoutContext();
	const userLanguage = useUnit($userLanguage);

	useEffect(() => {
		i18n.changeLanguage(userLanguage);
	}, [userLanguage]);

	const userPlayBlocked = useUnit($$userPlayBlock);
	const userGeoBlocked = false; //useUnit($$userGeoBlock);

	const allowedToNavigate = React.useMemo(() => !userPlayBlocked && !userGeoBlocked, [userPlayBlocked, userGeoBlocked]);

	const onLogoClicked = () => {
		navigateToHomepage();
	};

	const onCurrencyMenuBuyCryptoClicked = () => {
		toggleWalletModal();
	};

	const onCurrencySelected = () => {
		toggleWalletModal();
	};

	return (
		<>
			<MainLayout
				variant={isCompactMode ? 'compact' : isGameMode ? 'fullscreen' : 'default'}
				avatar={
					<Avatar onClick={toggleProfileModal}>
						<AvatarImage src="/logo.webp" alt="Avatar" />
					</Avatar>
				}
				infoBar={
					<InfoBar>
						<InfoBar.Wallet>
							<InfoBar.CurrencySelector>
								<CurrencyMenu onBuyCryptoClicked={onCurrencyMenuBuyCryptoClicked} />
							</InfoBar.CurrencySelector>
							<InfoBar.DepositButton>
								<Button
									onClick={onCurrencySelected}
									aria-label="Add currency"
									className="xxs:my-px inline-flex items-center justify-center whitespace-nowrap rounded-md border-1 border-solid border-brandblue-300 bg-gradient-primary p-4 text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 active:opacity-90 disabled:pointer-events-none disabled:opacity-50 xxs:h-7 xxs:min-w-7 xxs:rounded-sm xxs:px-[0.24rem] xxs:py-[0.21rem] sm:min-w-9 sm:rounded-lg sm:px-[0.7rem] sm:py-[0.45rem]"
								>
									<PlusIcon />
								</Button>
							</InfoBar.DepositButton>
						</InfoBar.Wallet>
						<InfoBar.NotificationCenter>
							<NotificationsCenter />
						</InfoBar.NotificationCenter>
					</InfoBar>
				}
				footer={<Footer />}
				logo={<img className="size-9" src="/logo.webp" alt="Whale" onClick={onLogoClicked} />}
			>
				{userPlayBlocked && <PlayBlockModal open={userPlayBlocked} />}
				{userGeoBlocked && <GeoBlockModal open={userGeoBlocked} />}
				<>
					{!isGameMode && !isStickyMenuHidden && (
						<StickyBottomMenu
							onMenuClicked={toggleSidebar}
							onCasinoClicked={navigateToCasino}
							onSportsbookClicked={navigateToSportsbook}
							onProfileClicked={toggleProfileModal}
							onEarnClicked={toggleEarnModal}
						/>
					)}
					<Sidebar open={isSidebarOpened} />
					<ProfileModal open={isProfileModalOpened} />
					<EarnModal open={isEarnModalOpened} />
					<WalletModal open={isWalletModalOpened} />
					<SettingsModal open={isSettingsModalOpened} />

					<Toaster />
				</>
				{isGameInformationViewOpened ? <GameInformation /> : children}
			</MainLayout>
		</>
	);
};

export default Layout;
