import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ar from '../languages/ar.json';
import en from '../languages/en.json';
import es from '../languages/es.json';
import fa from '../languages/fa.json';
import fr from '../languages/fr.json';
import id from '../languages/id_ID.json';
import it from '../languages/it.json';
import ja from '../languages/ja.json';
import ko from '../languages/ko.json';
import pt from '../languages/pt.json';
import ru from '../languages/ru.json';
import sq from '../languages/sq.json';
import tr from '../languages/tr.json';
import uz from '../languages/uz.json';
import vi from '../languages/vi.json';
import zh from '../languages/zh_CN.json';

const SUPPORTED_TRANSLATIONS = { ar, en, es, fa, fr, id, it, ja, ko, pt, ru, sq, tr, uz, vi, zh };

const setupLanguages = async (): Promise<void> => {
	try {
		const defaultLanguage = 'en';

		const resources = Object.entries(SUPPORTED_TRANSLATIONS).reduce((acc, [language, translation]) => {
			acc[language] = { translation };
			return acc;
		}, {});

		await i18n.use(initReactI18next).init({
			resources,
			lng: defaultLanguage,
			fallbackLng: defaultLanguage,
			interpolation: {
				escapeValue: false,
			},
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to setup languages', error);
	}
};

export const I18n = { setupLanguages };
