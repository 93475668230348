import * as React from 'react';

import { SVGProps } from 'react';

const SvgTon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 56 56" {...props}>
		<circle cx={28} cy={28} r={28} fill="#08C" />
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M20.209 18.504h15.704c.556 0 1.111.082 1.691.352.696.325 1.065.836 1.323 1.213q.03.045.056.091c.304.541.46 1.125.46 1.753 0 .597-.141 1.247-.46 1.814l-.009.016-9.922 17.043a1.217 1.217 0 0 1-2.108-.007l-9.74-17.007-.008-.014c-.223-.367-.568-.935-.628-1.668a3.32 3.32 0 0 1 .435-1.936 3.3 3.3 0 0 1 1.462-1.34c.657-.307 1.323-.31 1.744-.31m6.574 2.435h-6.574c-.432 0-.598.027-.714.08a.87.87 0 0 0-.385.354.9.9 0 0 0-.116.517c.009.104.051.222.3.633l.015.026 7.474 13.05zm2.435 0v14.724l7.646-13.135a1.3 1.3 0 0 0 .145-.615c0-.188-.04-.35-.126-.519a2 2 0 0 0-.194-.248.4.4 0 0 0-.114-.083c-.18-.084-.365-.124-.662-.124z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgTon;
