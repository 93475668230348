import * as React from 'react';

type BadgeProps = {
	children: React.ReactNode;
};

interface BadgeNamespace {
	Text: React.FC<BadgeProps>;
}

const Badge: React.FC<BadgeProps> & BadgeNamespace = ({ children }) => {
	return <div className="rounded-lg bg-[#FFAE4B1A] px-1.5 py-px">{children}</div>;
};

Badge.Text = ({ children }: BadgeProps) => {
	return <span className="text-xs font-semibold text-[#FFAE4B]">{children}</span>;
};

export default Badge;
