import { sample } from 'effector';

import { domain } from './domain';
import { loadLatestGamesFx, searchGamesFx, gamesSessionFx, liveRtpFx } from './effects';
import { loadLatestGames, updateSearchTerm, loadGameSession, loadLiveGameRtp } from './events';
import { Games, GameURL, LiveStats } from './types';

export const $latestGames = domain.store<Games>([]);
export const $searchTerm = domain.store<string>('');
export const $filteredGames = domain.store<Games>([]);
export const $previousFilteredGames = domain.store<Games>([]);

export const $liveStats = domain.store<LiveStats>({});
export const $gameUrl = domain.store<GameURL>('');

export const $$latestGamesLoading = loadLatestGamesFx.pending.map((pending) => pending);
export const $$searchGamesLoading = searchGamesFx.pending.map((pending) => pending);
export const $$liveRtpLoading = loadLatestGamesFx.pending.map((pending) => pending);
export const $$gameSessionLoading = loadLatestGamesFx.pending.map((pending) => pending);

export const initEvents = () => {
	sample({
		clock: loadLatestGames,
		target: loadLatestGamesFx,
	});

	sample({
		clock: loadLatestGamesFx.doneData,
		target: $latestGames,
	});

	sample({
		clock: updateSearchTerm,
		target: searchGamesFx,
	});

	sample({
		clock: searchGamesFx.doneData,
		target: $filteredGames,
	});

	sample({
		clock: loadGameSession,
		target: gamesSessionFx,
	});

	sample({
		clock: gamesSessionFx.doneData,
		target: $gameUrl,
	});

	sample({
		clock: searchGamesFx,
		source: $filteredGames,
		target: $previousFilteredGames,
	});

	sample({
		clock: searchGamesFx.fail,
		source: $previousFilteredGames,
		target: $filteredGames,
	});
};
