import * as React from 'react';

type SvgTrendUpProps = React.SVGProps<SVGSVGElement> & {
	strokeColor?: string;
};

const SvgTrendUp: React.FC<SvgTrendUpProps> = ({ strokeColor = '#FF6659', ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<path
			stroke={strokeColor}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.333}
			d="M4 12L12 4M12 4H6.66667M12 4V9.33333"
		/>
	</svg>
);

export default SvgTrendUp;
