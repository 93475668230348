import { Currency } from '@whale-client/dataflow-common';

export class WithdrawalError extends Error {
	constructor(public readonly error: string) {
		super(error);
	}
}

export class DepositError extends Error {
	constructor(public readonly error: string) {
		super(error);
	}
}

export type TUserBalances = Partial<Record<Currency, number>>;

export type TWallet = {
	id: number;
	currency: Currency;
	network: string;
	depositAddress: string;
	withdrawalAddress: string;
	default: boolean;
};
