import React from 'react';

type InfoBarProps = {
	children: React.ReactNode;
};

interface InfoBarNamespace {
	CurrencySelector: React.FC<InfoBarProps>;
	DepositButton: React.FC<InfoBarProps>;
	NotificationCenter: React.FC<InfoBarProps>;
	Profile: React.FC<InfoBarProps>;
	Wallet: React.FC<InfoBarProps>;
}

const InfoBar: React.FC<InfoBarProps> & InfoBarNamespace = ({ children }) => {
	return <div className="flex flex-row items-center justify-center gap-3">{children}</div>;
};

InfoBar.CurrencySelector = ({ children }: InfoBarProps) => {
	return <>{children}</>;
};

InfoBar.CurrencySelector = ({ children }: InfoBarProps) => {
	return <>{children}</>;
};

InfoBar.Wallet = ({ children }: InfoBarProps) => {
	return (
		<div className="flex flex-row items-center rounded-[10px] border-[0.01em] border-[#15181f] bg-primary">
			{children}
		</div>
	);
};

InfoBar.DepositButton = ({ children }: InfoBarProps) => {
	return <>{children}</>;
};

InfoBar.NotificationCenter = ({ children }: InfoBarProps) => {
	return <>{children}</>;
};

InfoBar.Profile = ({ children }: InfoBarProps) => {
	return <>{children}</>;
};

export default InfoBar;
