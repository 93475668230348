import { Request } from '@whale-client/dataflow-utils';
import { domain } from '../domain';

const openTelegramAuth = () => {
	return new Promise((resolve, reject) => {
		if (!window.Telegram?.Login) {
			reject('Telegram Login not found');
			return;
		}
		window.Telegram.Login.auth({ bot_id: window.env.PUBLIC_BOT_ID, request_access: false }, resolve);
	});
};

export const signInTelegramFx = domain.createEffect(async () => {
	const data = await openTelegramAuth();
	await Request.post<{ token: string }>('/auth/tg-sync', data);
});
