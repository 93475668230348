import useLayoutContext from './useLayoutContext';

interface TelegramWebApp {
	onEvent(event: string, cb: () => void): unknown;
	BackButton: {
		hide: () => void;
		show: () => void;
	};
	ready: () => void;
	enableClosingConfirmation: () => void;
	expand: () => void;
	disableVerticalSwipes: () => void;
	HapticFeedback: {
		notificationOccurred: (type: 'success') => void;
		selectionChanged: () => void;
	};
}

interface Telegram {
	WebApp: TelegramWebApp;
}

const noop = () => {};

const fallbackTelegram = {
	initWebApp: noop,
	successHapticFeedback: noop,
	selectionHapticFeedback: noop,
	enableClosingConfirmation: noop,
	showBackButton: noop,
	hideBackButton: noop,
};

const useTelegram = () => {
	const Telegram = window?.Telegram as Telegram | undefined;

	if (!Telegram) {
		return fallbackTelegram;
	}

	const initApplication = () => {
		Telegram.WebApp.ready();
		Telegram.WebApp.enableClosingConfirmation();
		Telegram.WebApp.expand();
		Telegram.WebApp.disableVerticalSwipes();
	};

	const enableClosingConfirmation = () => {
		Telegram.WebApp.enableClosingConfirmation();
	};

	const hapticSuccess = () => {
		Telegram.WebApp.HapticFeedback.notificationOccurred('success');
	};

	const hapticSelection = () => {
		Telegram.WebApp.HapticFeedback.selectionChanged();
	};

	const hideBackButton = () => {
		Telegram.WebApp.BackButton.hide();
	};

	const showBackButton = () => {
		Telegram.WebApp.BackButton.show();

		Telegram.WebApp.onEvent('backButtonClicked', () => {
			window.history.go(-1);
		});
	};

	return {
		initWebApp: initApplication,
		successHapticFeedback: hapticSuccess,
		selectionHapticFeedback: hapticSelection,
		enableClosingConfirmation,
		hideBackButton,
		showBackButton,
	};
};

export default useTelegram;
