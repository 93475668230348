import * as React from 'react';

import './../../styles/tw.css';

import AppLayout, { type LayoutVariant } from './AppLayout';

import Header from '@/components/ui/header';
import { Separator } from '@/components/ui/separator';

type MainLayoutProps = {
	children: React.ReactNode;
	logo: React.ReactNode;
	avatar: React.ReactNode;
	infoBar: React.ReactNode;
	footer: React.ReactNode;
	variant?: LayoutVariant;
};

const MainLayout = ({ children, avatar, logo, infoBar, footer, variant = 'default' }: MainLayoutProps) => {
	const showControls = variant === 'default' || variant === 'compact';

	return (
		<AppLayout variant={variant}>
			{showControls && (
				<>
					<AppLayout.Header>
						<Header>
							<Header.Navigation>
								<Header.Logo>{logo}</Header.Logo>
								<Header.Menu>
									{infoBar}
									{avatar}
								</Header.Menu>
							</Header.Navigation>
						</Header>
					</AppLayout.Header>
					<Separator />
				</>
			)}
			<AppLayout.Main>{children}</AppLayout.Main>
			{showControls && <AppLayout.Footer>{footer}</AppLayout.Footer>}
		</AppLayout>
	);
};

export default MainLayout;
