import { Centrifuge, State } from 'centrifuge';

import { Request } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { addChannelMessage, setChannelMessages } from './events';
import { Message } from './types';

export const loadConnectionTokenFx = domain.createEffect(async () => {
	const response = await Request.get<{ socket: string; chat: string }>('/auth/socket-chat');
	return response.data;
});

let connection: Centrifuge;

export const initializeConnectionFx = domain.createEffect(async ({ connectionToken, subscriptionToken }) => {
	if (!connectionToken || !subscriptionToken) {
		return null;
	}
	if (connection) {
		connection.disconnect();
	}
	connection = new Centrifuge(`${window.env.PUBLIC_SOCKET_URL}/connection/websocket`, {
		token: connectionToken,
	});
	connection.on('error', (error) => {
		// eslint-disable-next-line no-console
		console.error('WebSocket connection error', error);
	});

	if (connection.state === State.Disconnected) {
		connection.connect();
	}

	await connection.ready();

	const subscription = connection.newSubscription('public:chat', { token: subscriptionToken });

	subscription.on('subscribed', async () => {
		try {
			const history = await subscription.history({ limit: 1000 });
			if (history && history?.publications?.length) {
				setChannelMessages(history.publications.map((item) => item.data as Message));
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('Failed to get history', e);
		}
	});

	subscription.on('publication', async (ctx) => {
		addChannelMessage(ctx.data as Message);
	});

	subscription.subscribe();
});
