import { Request } from '@whale-client/dataflow-utils';
import { domain } from '../domain';
import { TVerifyUserData } from '../types';

export const verifyUserDataFx = domain.createEffect(async (data: TVerifyUserData) => {
	const response = await Request.post('/user/verify', data);
	const { error } = response.data;
	if (error) {
		throw new Error(error);
	}
});
