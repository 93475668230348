import { createEffect, sample, StoreWritable } from 'effector';

import { initCache } from './events';

export const cacheStringStore = <T extends string>($store: StoreWritable<T>, key: string) => {
	const pageLoadedFx = createEffect(() => {
		const cachedValue = localStorage.getItem(key) as T | null;
		$store.watch((string) => {
			localStorage.setItem(key, string);
		});
		if (cachedValue !== null) {
			return cachedValue;
		}
		return $store.defaultState;
	});

	sample({
		clock: initCache,
		target: pageLoadedFx,
	});
	sample({
		clock: pageLoadedFx.doneData,
		target: $store,
	});
};
