import * as React from 'react';

import TelegramIcon from '@/lib/icons/Telegram';
import TrendDownIcon from '@/lib/icons/TrendDown';
import TrendUpIcon from '@/lib/icons/TrendUp';

type GameInformationProps = {
	children: React.ReactNode;
};

type GameInformationGameCardProps = {
	name: string;
	image: React.ReactNode;
	providerName: string;
	providerLabel: string;
};

type GameInformationStatProps = {
	label: string;
	value: string;
	className?: string;
};

type GameInformationStatsProps = {
	children: React.ReactNode;
};

type Trend = 'up' | 'down';

interface TimePeriodStats {
	trend?: Trend;
	rtp?: number;
}

export interface LiveStats {
	day: TimePeriodStats;
	week: TimePeriodStats;
	month: TimePeriodStats;
}

type GameInformationLiveRTPProps = {
	stats: LiveStats;
	dayLabel: string;
	weekLabel: string;
	monthLabel: string;
	notAvailableLabel: string;
};

type GameInformationCallToActionProps = {
	children: React.ReactNode;
};

interface GameInformationNamespace {
	GameCard: React.FC<GameInformationGameCardProps>;
	GameStats: React.FC<GameInformationStatsProps>;
	GameStat: React.FC<GameInformationStatProps>;
	LiveRTP: React.FC<GameInformationLiveRTPProps>;
	CallToAction: React.FC<GameInformationCallToActionProps>;
}

const GameInformation: React.FC<GameInformationProps> & GameInformationNamespace = ({ children }) => {
	return (
		<div className="flex h-[364px] w-full flex-col justify-between">
			<div className="flex flex-col gap-y-4">{children}</div>
		</div>
	);
};

GameInformation.GameCard = ({ name, providerName, providerLabel, image }: GameInformationGameCardProps) => {
	return (
		<div className="w-full rounded-t-2xl bg-primary p-5">
			<div className="flex gap-x-5">
				{image}
				<div className="flex flex-col gap-y-2">
					<h2 className="text-xl font-semibold text-white">{name}</h2>
					<div className="flex flex-col gap-y-0.5">
						<span className="text-xs font-medium text-brandblue-50">{providerName}</span>
						<span className="text-sm font-medium text-white">{providerLabel}</span>
					</div>
					<TelegramIcon />
				</div>
			</div>
		</div>
	);
};

GameInformation.GameStat = ({ label, value, className = '' }: GameInformationStatProps) => {
	return (
		<div className={`flex items-center justify-between gap-x-1.5 px-3 py-2.5 ${className}`}>
			<span className="text-sm font-medium text-brandblue-100">{label}</span>
			<span className="text-sm font-semibold text-white">{value}</span>
		</div>
	);
};

GameInformation.GameStats = ({ children }: GameInformationStatsProps) => {
	return <div className="flex flex-col gap-y-px">{children}</div>;
};

GameInformation.CallToAction = ({ children }: GameInformationCallToActionProps) => {
	return <div className="flex flex-col gap-y-px">{children}</div>;
};

GameInformation.LiveRTP = ({
	stats,
	dayLabel,
	weekLabel,
	monthLabel,
	notAvailableLabel,
}: GameInformationLiveRTPProps) => {
	const renderTrendIcon = (trend?: Trend) => {
		if (!trend) return null;
		return <>{trend === 'down' ? <TrendDownIcon /> : <TrendUpIcon />}</>;
	};

	return (
		<div className="flex items-center justify-between rounded-t-sm bg-primary p-2">
			{['day', 'week', 'month'].map((period) => (
				<div key={period} className="flex items-center gap-x-1.5 rounded-md bg-primary-active p-1">
					<span className="text-xs font-medium text-brandblue-100">
						{period === 'day' ? dayLabel : period === 'week' ? weekLabel : monthLabel}
					</span>
					<span className="text-xs font-semibold text-white">
						{stats[period as keyof LiveStats].rtp ? `${stats[period as keyof LiveStats].rtp}%` : notAvailableLabel}
					</span>
					{renderTrendIcon(stats[period as keyof LiveStats].trend)}
				</div>
			))}
		</div>
	);
};

export default GameInformation;
