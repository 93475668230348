import { Request } from '@whale-client/dataflow-utils';
import { domain } from '../domain';
import { signInFail } from '../events';

import { loadUserDataFx } from './loadUserDataFx';

export const initTgAppFx = domain.createEffect(async () => {
	try {
		await loadUserDataFx();
	} catch (error) {
		try {
			await Request.post('/auth/tgapp-sync', { data: window.Telegram?.WebApp.initData });
			await loadUserDataFx();
		} catch (error) {
			if (error instanceof Error) {
				signInFail(error.message);
			}
		}
	}
});
