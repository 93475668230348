import { sample } from 'effector';

import { cacheJsonStore } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { loadConnectionTokenFx, initializeConnectionFx } from './effects';
import { loadConnectionToken, initNotificationChannel, setChannelMessages, addChannelMessage } from './events';
import { Message } from './types';

const $tokens = domain.store<{ connectionToken: string; subscriptionToken: string }>({
	subscriptionToken: '',
	connectionToken: '',
});

export const $messages = domain.store<Message[]>([]);

export const initEvents = () => {
	cacheJsonStore($tokens, 'chat:tokens');

	$messages.on(addChannelMessage, (messages, message) => [...messages, message]);
	$messages.on(setChannelMessages, (_, messages) => messages);

	// Load connection token
	sample({
		clock: loadConnectionToken,
		target: loadConnectionTokenFx,
	});
	sample({
		clock: loadConnectionTokenFx.doneData,
		fn: ({ socket, chat }) => ({
			connectionToken: socket,
			subscriptionToken: chat,
		}),
		target: [$tokens, initializeConnectionFx],
	});

	sample({
		source: $tokens,
		clock: initNotificationChannel,
		filter: ({ connectionToken, subscriptionToken }) => !!(connectionToken && subscriptionToken),
		target: initializeConnectionFx,
	});

	sample({
		source: $tokens,
		clock: initNotificationChannel,
		filter: ({ connectionToken, subscriptionToken }) => !(connectionToken && subscriptionToken),
		target: loadConnectionToken,
	});
};
