import { sample } from 'effector';

import { domain } from './domain';
import { loadStatsFx, loadWhaleTokenStatsFx, loadAffiliatedUsersFx } from './effects';
import { loadStats, loadWhaleToken, loadAffiliatedUsers, claimEarnings } from './events';
import { TAffiliatedUser } from './types';

export const $affiliatesCount = domain.store(0);
export const $commissionAmount = domain.store(0);
export const $wageringAmount = domain.store(0);

export const $whaleTokenCurrency = domain.store('WHALE');
export const $whaleTokenAvailableAmount = domain.store(0);
export const $whaleTokenEarnedAmount = domain.store(0);
export const $whaleTokenTotalAmount = domain.store(0);

export const $affiliatedUsers = domain.store<TAffiliatedUser[]>([]);
export const $affiliatedUsersTotalPages = domain.store(0);
export const $affiliatedUsersCurrentPage = domain.store(1);
export const $affiliatedUsersPerPage = domain.store(10);

export const $$statsLoading = loadStatsFx.pending.map((loading) => loading);
export const $$whaleTokenStatsLoading = loadWhaleTokenStatsFx.pending.map((loading) => loading);
export const $$affiliatedUsersLoading = loadAffiliatedUsersFx.pending.map((loading) => loading);

export const initEvents = () => {
	// Load Stats
	sample({
		clock: loadStats,
		target: loadStatsFx,
	});
	sample({
		clock: loadStatsFx.doneData,
		target: $affiliatesCount,
		fn: (data) => data.affiliates,
	});
	sample({
		clock: loadStatsFx.doneData,
		target: $commissionAmount,
		fn: (data) => data.commission,
	});
	sample({
		clock: loadStatsFx.doneData,
		target: $wageringAmount,
		fn: (data) => data.wagering,
	});

	// Load Whale Token Stats
	sample({
		clock: loadWhaleToken,
		target: loadWhaleTokenStatsFx,
	});
	sample({
		clock: loadWhaleTokenStatsFx.doneData,
		target: $whaleTokenCurrency,
		fn: (data) => data.currency,
	});
	sample({
		clock: loadWhaleTokenStatsFx.doneData,
		target: $whaleTokenAvailableAmount,
		fn: (data) => data.availableAmount,
	});
	sample({
		clock: loadWhaleTokenStatsFx.doneData,
		target: $whaleTokenEarnedAmount,
		fn: (data) => data.earnedAmount,
	});
	sample({
		clock: loadWhaleTokenStatsFx.doneData,
		target: $whaleTokenTotalAmount,
		fn: (data) => data.totalAmount,
	});

	// Load Affiliated Users
	sample({
		clock: loadAffiliatedUsers,
		fn: ({ page, perPage = 10 }: { page: number; perPage?: number }) => ({ page, perPage }),
		target: loadAffiliatedUsersFx,
	});
	sample({
		clock: loadAffiliatedUsersFx.doneData,
		fn: ({ data }) => data,
		target: $affiliatedUsers,
	});
	sample({
		clock: loadAffiliatedUsersFx.doneData,
		fn: ({ page: { current } }) => current,
		target: $affiliatedUsersCurrentPage,
	});
	sample({
		clock: loadAffiliatedUsersFx.doneData,
		fn: ({ page: { total } }) => total,
		target: $affiliatedUsersTotalPages,
	});
	sample({
		clock: loadAffiliatedUsersFx.doneData,
		fn: ({ page: { perPage } }) => perPage,
		target: $affiliatedUsersPerPage,
	});
};
