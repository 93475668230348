import { Request } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { TransactionsResponse, Stats } from './types';

export const fetchTransactionsFX = domain.createEffect(async ({ offset, limit }: { offset: number; limit: number }) => {
	const response = await Request.get<TransactionsResponse>(`/transactions?offset=${offset}&limit=${limit}`);
	return response.data;
});

export const fetchStatsFx = domain.createEffect(async () => {
	const response = await Request.get<Stats>('/users/stats');
	return response.data;
});
