import { sample } from 'effector';

import { Currency, Language } from '@whale-client/dataflow-common';
import { cacheStringStore } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { checkEmailOtpFx } from './effects/checkEmailOtp';
import { getUserLanguageFx } from './effects/getUserLanguageFx';
import { getUserLogoImaFx } from './effects/getUserLogoImaFx';
import { getUserNameFromTgApiFx } from './effects/getUserNameFromTgApiFx';
import { getUserPlatformFx } from './effects/getUserPlatformFx';
import { initTgAppFx } from './effects/initTgAppFx';
import { loadUserDataFx } from './effects/loadUserDataFx';
import { loadUserGeoFx } from './effects/loadUserGeoFx';
import { loadUserLevelStatsFx } from './effects/loadUserLevelFx';
import { loadUserWageringStatsFx } from './effects/loadWageringStatsFx';
import { signInEmailFx } from './effects/signInEmailFx';
import { signInMiniPayFx } from './effects/signInMiniPayFx';
import { signInTelegramFx } from './effects/signInTelegramFx';
import { signOutFx } from './effects/signOutFx';
import { updateUserLanguageFx } from './effects/updateUserLanguageFx';
import { updateUserMainCurrencyFx } from './effects/updateUserMainCurrencyFx';
import { verifyUserDataFx } from './effects/verifyUserDataFx';
import {
	initTg,
	loadUserData,
	loadUserGeo,
	loadUserLevelStats,
	loadUserWageringStats,
	sendEmailOTP,
	setMainCurrency,
	setSystemLanguage,
	signInEmail,
	signInMiniPay,
	signInSuccess,
	signInTelegram,
	signOut,
	verifyUserData,
	verifyUserDataFail,
	verifyUserDataSuccess,
} from './events';
import { TUserData, UserLevelStats, UserWageringStats } from './types';

export const $userName = domain.store<string>('');
export const $userLogo = domain.store<string>('');
export const $userLanguage = domain.store<Language>(Language.EN);
export const $userData = domain.store<TUserData | null>(null);

export const $userLevelStats = domain.store<UserLevelStats | null>(null);
export const $userWageringStats = domain.store<UserWageringStats | null>(null);

export const $userGeo = domain.store<string>('ZZ');
export const $userGeoAllowed = domain.store<boolean>(true);

export const $loginTimestamp = domain.store<number | null>(null);

export const $tgPlatform = domain.createStore<string>('unknown');

const $emailOTPhash = domain.store<string>('');

export const $$mainCurrency = $userData.map((data) => data?.mainCurrency || Currency.TON);
export const $$userPlayBlock = $userData.map((data) => data?.playBlock || false);
export const $$userGeoBlock = $userGeoAllowed.map((allowed) => allowed);
export const $$userAffiliateCode = $userData.map((data) => data?.affiliateCode);

export const $$isAuthenticated = $loginTimestamp.map((loginTime) => !!loginTime);
export const $$userId = $userData.map((data) => data?.id || null);

export const $$isEmailOtpStep = $emailOTPhash.map((hash) => !!hash);

export const initEvents = () => {
	cacheStringStore($emailOTPhash, 'emailOTPhash');

	sample({
		clock: initTg,
		target: [getUserNameFromTgApiFx, getUserLogoImaFx, getUserPlatformFx, getUserLanguageFx, initTgAppFx],
	});
	sample({
		clock: getUserNameFromTgApiFx.doneData,
		target: $userName,
	});
	sample({
		clock: initTgAppFx.done,
		target: signInSuccess,
	});
	sample({
		clock: getUserLogoImaFx.doneData,
		target: $userLogo,
	});
	sample({
		clock: getUserPlatformFx.doneData,
		target: $tgPlatform,
	});
	sample({
		clock: getUserLanguageFx.doneData,
		target: $userLanguage,
	});

	sample({
		clock: signInTelegram,
		target: signInTelegramFx,
	});

	sample({
		clock: signInTelegramFx.doneData,
		target: signInSuccess,
	});
	$loginTimestamp.on(signInSuccess, () => Date.now());

	sample({
		clock: signOut,
		target: signOutFx,
	});

	$loginTimestamp.on(signOut, () => $loginTimestamp.defaultState);
	$userLogo.on(signOut, () => $userLogo.defaultState);
	$userName.on(signOut, () => $userName.defaultState);
	$userData.on(signOut, () => $userData.defaultState);

	sample({
		clock: signInEmail,
		target: signInEmailFx,
	});
	sample({
		clock: signInEmailFx.doneData,
		target: $emailOTPhash,
		fn: (data) => data.hash,
	});

	sample({
		clock: sendEmailOTP,
		target: checkEmailOtpFx,
	});
	sample({
		clock: checkEmailOtpFx.doneData,
		target: [$emailOTPhash, signInSuccess],
		fn: () => '',
	});

	sample({
		clock: signInMiniPay,
		target: signInMiniPayFx,
	});

	sample({
		clock: signInMiniPayFx.doneData,
		target: signInSuccess,
	});

	sample({
		clock: loadUserData,
		target: loadUserDataFx,
	});

	sample({
		clock: loadUserLevelStats,
		target: loadUserLevelStatsFx,
	});

	sample({
		clock: loadUserWageringStats,
		target: loadUserWageringStatsFx,
	});

	sample({
		clock: loadUserLevelStatsFx.doneData,
		target: $userLevelStats,
	});

	sample({
		clock: loadUserWageringStatsFx.doneData,
		target: $userWageringStats,
	});

	sample({
		clock: loadUserDataFx.doneData,
		target: $userData,
	});

	sample({
		clock: loadUserDataFx.doneData,
		filter: $loginTimestamp.map((loginTime) => !loginTime),
		target: [$loginTimestamp],
		fn: () => Date.now(),
	});

	sample({
		clock: $userData,
		target: $userName,
		filter: (userData) => !!userData,
		fn: (userData) => userData?.telegramFirstName || $userName.getState(),
	});

	sample({
		clock: $userData,
		target: $userLogo,
		filter: (userData) => !!userData,
		fn: (userData) => userData?.telegramPhoto || $userLogo.getState(),
	});

	sample({
		clock: $userData,
		target: $userLanguage,
		filter: (userData) => !!userData,
		fn: (userData) => (userData?.serviceLanguage as Language) || $userLanguage.getState(),
	});

	sample({
		clock: loadUserGeo,
		target: loadUserGeoFx,
	});
	sample({
		clock: loadUserGeoFx.doneData,
		fn: (data) => data.country,
		target: $userGeo,
	});
	sample({
		clock: loadUserGeoFx.doneData,
		fn: (data) => data.allowed,
		target: $userGeoAllowed,
	});

	// Set Main Currency
	$userData.on(setMainCurrency, (state, currency) => {
		if (!state) {
			return state;
		}
		return {
			...state,
			mainCurrency: currency,
		};
	});
	sample({
		clock: setMainCurrency,
		target: updateUserMainCurrencyFx,
	});
	sample({
		clock: updateUserMainCurrencyFx.doneData,
		target: $userData,
	});

	// Set System Language
	$userData.on(setSystemLanguage, (state, language) => {
		if (!state) {
			return state;
		}
		return {
			...state,
			serviceLanguage: language,
		};
	});
	sample({
		clock: setSystemLanguage,
		target: updateUserLanguageFx,
	});
	sample({
		clock: updateUserLanguageFx.doneData,
		target: $userData,
	});

	// Verify user data
	sample({
		clock: verifyUserData,
		target: verifyUserDataFx,
	});
	sample({
		clock: verifyUserDataFx.done,
		target: [loadUserData, verifyUserDataSuccess],
	});
	sample({
		clock: verifyUserDataFx.fail,
		target: verifyUserDataFail,
		fn: ({ error }) => error.message,
	});
};
