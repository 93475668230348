export const HOMEPAGE_ROUTE: string = '/';
export const CASINO_ROUTE: string = '/casino';
export const LIVE_CASINO_ROUTE: string = '/live-casino';
export const CRYPTO_CASINO_ROUTE: string = '/crypto-games';
export const SPORTSBOOK_ROUTE: string = '/sportsbook';
export const PROMOTIONS_ROUTE: string = '/promotions';
export const ROADMAP_ROUTE: string = '/roadmap';
export const FUTURES_ROUTE: string = '/game-provider/bitblox-games/updownturbo';

export const EARN_ROUTE: string = '/?modal=earn';
export const PROFILE_ROUTE: string = '/?modal=profile';
export const WALLET_ROUTE: string = '/?modal=wallet';
export const SETTINGS_ROUTE: string = '/?modal=settings';

export const CASHBACK_PROMOTION_ROUTE: string = 'unlock-your-rewards-dive-into-daily-cashbacks-at-whale';
export const REFER_A_FRIEND_PROMOTION_ROUTE: string =
	'unlock-rewards-with-refer-a-friend-join-the-fun-and-reap-the-benefits';
export const REFER_A_FRIEND_COMPETITION_PROMOTION_ROUTE: string = 'refer-a-friend-competition';
export const SEASON2_PROMOTION_ROUTE: string = 'epic-rewards-level-up-season-2-one-million-race';
export const WHALE_TOKEN_ROUTE: string = 'whale-token-airdrop';

const WHALES_CHOICE_GAME_ROUTE: string = '/game-provider/red-tiger-gaming/los-muertos-locos';
export const WHALES_CHOICE_BANNER_HOMEPAGE_ROUTE: string = WHALES_CHOICE_GAME_ROUTE;
export const GAME_BANNER_HOMEPAGE_ROUTE: string = '/game-provider/one-touch/whale-plinko';
export const BETTING_BANNER_HOMEPAGE_ROUTE: string = '/game-provider/one-touch/whale-plinko';

export const WHALES_CHOICE_BANNER_CASINO_ROUTE: string = WHALES_CHOICE_GAME_ROUTE;
export const SEASON_2_BANNER_CASINO_ROUTE: string = '/promotions/epic-rewards-level-up-season-2-one-million-race';
export const WHALE_TOKEN_BANNER_CASINO_ROUTE: string = '/promotions/whale-token-airdrop';

export const WHALES_CHOICE_BANNER_LIVE_CASINO_ROUTE: string = '/game-provider/evolution-gaming/evo_livebaccaratlobby';
export const GAME_OF_THE_WEEK_BANNER_LIVE_CASINO_ROUTE: string = '/game-provider/evolution-gaming/lightning-storm';
export const GAME_LIVE_BANNER_CASINO_ROUTE: string = '/game-provider/bombay-live/bombay-livebaccarat-lobby';

export const PROMOTION_BANNER_CRYPTO_GAMES_ROUTE: string = `/${PROMOTIONS_ROUTE}/${CASHBACK_PROMOTION_ROUTE}`;
export const CRASH_GAME_BANNER_CRYPTO_GAMES_ROUTE: string = '/game-provider/turbogames/crash';
export const MINE_GAME_BANNER_CRYPTO_GAMES_ROUTE: string = '/game-provider/turbogames/mines';
