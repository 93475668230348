import { Language, Telegram } from '@whale-client/dataflow-common';
import { domain } from '../domain';

declare global {
	interface Window {
		Telegram?: Telegram;
	}
}

function isLanguage(lang: string): lang is Language {
	return ['en', 'ru', 'es'].includes(lang);
}

export const getUserLanguageFx = domain.createEffect(() => {
	const tgLang = window?.Telegram?.WebApp.language;
	if (tgLang && isLanguage(tgLang)) {
		return tgLang as Language;
	}
	return Language.EN;
});
