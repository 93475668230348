import { Currency } from '@whale-client/dataflow-common';

import { domain } from './domain';
import { TVerifyUserData } from './types';

export const signInTelegram = domain.createEvent('signInTelegram');
export const signInEmail = domain.createEvent<string>('signInEmail');
export const sendEmailOTP = domain.createEvent<string>('sendEmailOTP');
export const signInMiniPay = domain.createEvent('signInMiniPay');

export const signInSuccess = domain.createEvent('signInSuccess');
export const signInFail = domain.createEvent<string>('signInFail');
export const signOut = domain.createEvent('signOut');

export const initTg = domain.createEvent('initTg');

export const loadUserData = domain.createEvent('loadUserData');
export const loadUserGeo = domain.createEvent('loadUserGeo');
export const loadUserLevelStats = domain.createEvent('loadUserLevelStats');
export const loadUserWageringStats = domain.createEvent('loadUserWageringStats');

export const setMainCurrency = domain.createEvent<Currency>('setMainCurrency');
export const setSystemLanguage = domain.createEvent<string>('setSystemLanguage');

export const verifyUserData = domain.createEvent<TVerifyUserData>('verifyUserData');
export const verifyUserDataSuccess = domain.createEvent('verifyUserDataSuccess');
export const verifyUserDataFail = domain.createEvent<string>('verifyUserDataFail');
