import React, { createContext, useContext, ReactNode } from 'react';

export type LayoutVariant = 'default' | 'fullscreen' | 'compact';

interface AppLayoutContextType {
	variant: LayoutVariant;
}

const AppLayoutContext = createContext<AppLayoutContextType | undefined>(undefined);

export const useAppLayoutContext = () => {
	const context = useContext(AppLayoutContext);
	if (!context) {
		throw new Error('useAppLayoutContext must be used within an AppLayoutProvider');
	}
	return context;
};

type AppLayoutProps = {
	children: ReactNode;
	variant: LayoutVariant;
};

type AppLayoutElementProps = {
	children: ReactNode;
};

interface AppLayoutNamespace {
	Header: React.FC<AppLayoutElementProps>;
	Footer: React.FC<AppLayoutElementProps>;
	Main: React.FC<AppLayoutElementProps>;
	Sidebar: React.FC<AppLayoutElementProps>;
}

export const AppLayout: React.FC<AppLayoutProps> & AppLayoutNamespace = ({ children, variant }) => {
	return <AppLayoutContext.Provider value={{ variant }}>{children}</AppLayoutContext.Provider>;
};

AppLayout.Header = ({ children }: AppLayoutElementProps) => {
	return <header className="w-full px-4 py-2.5">{children}</header>;
};

AppLayout.Main = ({ children }: AppLayoutElementProps) => {
	const { variant } = useAppLayoutContext();

	return <main className={`flex flex-col gap-y-4  ${variant === 'default' ? 'p-4' : ''}`}>{children}</main>;
};

AppLayout.Sidebar = ({ children }: AppLayoutElementProps) => {
	return <aside className="w-64 p-4">{children}</aside>;
};

AppLayout.Footer = ({ children }: AppLayoutElementProps) => {
	return <footer className="w-full">{children}</footer>;
};

export default AppLayout;
