import { createEffect, sample, StoreWritable } from 'effector';

import { initCache } from './events';

export const cacheJsonStore = ($store: StoreWritable<any>, key: string) => {
	const pageLoadedFx = createEffect(() => {
		const cachedValue = localStorage.getItem(key);
		$store.watch((json) => {
			localStorage.setItem(key, JSON.stringify(json));
		});
		if (cachedValue !== null) {
			try {
				return JSON.parse(cachedValue);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				return $store.defaultState;
			}
		}
		return $store.defaultState;
	});
	sample({
		clock: initCache,
		target: pageLoadedFx,
	});
	sample({
		clock: pageLoadedFx.doneData,
		target: $store,
	});
};
