import { domain } from './domain';
import { Device } from './types';

export const detectDeviceFx = domain.createEffect(() => {
	if (typeof window !== 'undefined') {
		if (window.ethereum?.isMiniPay) {
			return Device.OPERA_MINI_APP;
		}

		!window.Telegram?.WebApp.initData && window.Telegram?.WebApp.ready();
		if (window.Telegram?.WebApp.initData) {
			return Device.TELEGRAM;
		}

		return Device.WEB;
	}

	return Device.WEB;
});
