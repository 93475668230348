import * as React from 'react';

const SvgNotification = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
		<path
			fill="#9092B2"
			d="M10.21 6.603H10a.636.636 0 0 1-.578-.838c.293-.737.561-1.49.779-2.228.184-.57.904-2.353 2.714-2.353a2.663 2.663 0 0 1 2.747 2.57c0 1.617-1.834 2.28-2.513 2.514-.959.25-1.949.363-2.94.335m2.696-4.188c-.963 0-1.399 1.114-1.507 1.457-.151.486-.31.972-.486 1.458a6.7 6.7 0 0 0 1.775-.252c.478-.167 1.676-.62 1.676-1.348a1.416 1.416 0 0 0-1.458-1.315"
		/>
		<path
			fill="#9092B2"
			d="M9.766 6.602a10.4 10.4 0 0 1-2.873-.351C4.807 5.572 4.38 4.508 4.38 3.738a2.41 2.41 0 0 1 .762-1.734 2.84 2.84 0 0 1 1.994-.837c1.407 0 2.119 1.315 2.646 2.253q.515 1.155.838 2.378a.63.63 0 0 1-.1.545.62.62 0 0 1-.486.26zM7.043 2.415a1.53 1.53 0 0 0-1.047.477 1.16 1.16 0 0 0-.393.838c0 .51.62 1.005 1.675 1.348.616.16 1.249.244 1.885.251q-.204-.67-.495-1.306l.495-.302-.545.302c-.486-.93-.905-1.592-1.55-1.608z"
		/>
		<path
			fill="#9092B2"
			d="M17.748 8.697V7.65a2.303 2.303 0 0 0-2.304-2.303H4.556A2.303 2.303 0 0 0 2.252 7.65v1.047zm-7.12 1.256v9.214h4.816a2.303 2.303 0 0 0 2.304-2.304v-6.91zm-1.256 0h-7.12v6.91a2.304 2.304 0 0 0 2.304 2.304h4.816z"
		/>
	</svg>
);

export default SvgNotification;
