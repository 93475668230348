// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DebouncedFunction<F extends (...args: any[]) => void> = (...args: Parameters<F>) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = <F extends (...args: any[]) => void>(
	func: F,
	wait: number,
	immediate: boolean = false,
): DebouncedFunction<F> => {
	let timeout: ReturnType<typeof setTimeout> | null = null;

	const debouncedFunction = function (this: ThisParameterType<F>, ...args: Parameters<F>) {
		const later = () => {
			timeout = null;
			if (!immediate) {
				func.apply(this, args);
			}
		};

		const callNow = immediate && !timeout;

		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(later, wait);

		if (callNow) {
			func.apply(this, args);
		}
	};

	return debouncedFunction;
};

export default debounce;
