import { combine, sample } from 'effector';

import { Currency } from '@whale-client/dataflow-common';
import { cacheStringStore } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import {
	loadBalancesFx,
	loadWalletsFx,
	createWalletFx,
	createWithdrawalRequestCryptoFx,
	createWithdrawalRequestBrazilRealFx,
	depositCryptobotFx,
	depositWalletFx,
} from './effects';
import {
	loadBalances,
	loadWallets,
	selectCurrency,
	selectNetwork,
	createWithdrawalRequestCrypto,
	createWithdrawalRequestBrazilReal,
	withdrawalRequestSuccess,
	withdrawalRequestError,
	createDepositRequestCryptobot,
	createDepositRequestWallet,
	resetWithdrawalError,
} from './events';
import { TUserBalances, TWallet } from './types';

const DEFAULT_BALANCES = {
	[Currency.TON]: 0,
	[Currency.NOT]: 0,
	[Currency.USDT]: 0,
	[Currency.BTC]: 0,
	[Currency.CUSD]: 0,
	[Currency.BRL]: 0,
	[Currency.BNB]: 0,
};

export const $balances = domain.store<TUserBalances>(DEFAULT_BALANCES);
export const $wallets = domain.store<TWallet[]>([]);

export const $selectedCurrency = domain.store<Currency>(Currency.TON);
export const $selectedNetwork = domain.store<string>('');

export const $depositError = domain.store<string | null>(null);
export const $withdrawalError = domain.store<string | null>(null);

export const $cryptochillDepositUrl = domain.store<string | null>(null);
export const $walletDepositUrl = domain.store<string | null>(null);

export const $$cryptochillDepositUrlLoading = depositCryptobotFx.pending;
export const $$walletDepositUrlLoading = depositWalletFx.pending;

export const $$availableCurrencies = $wallets.map((wallets) => {
	return [...new Set(wallets.map((wallet) => wallet.currency))];
});
export const $$availableNetworks = combine($wallets, $selectedCurrency, (wallets, currency) => {
	return wallets.filter((wallet) => wallet.currency === currency).map((wallet) => wallet.network);
});

export const $$selectedWallet = combine($wallets, $selectedCurrency, $selectedNetwork, (wallets, currency, network) => {
	return (
		wallets.find((wallet) => {
			if (wallet.currency !== currency) return false;
			if (network && wallet.network !== network) return false;
			return true;
		}) || null
	);
});

export const $$withdrawalLoading = combine(
	createWithdrawalRequestCryptoFx.pending,
	createWithdrawalRequestBrazilRealFx.pending,
	(crypto, brl) => crypto || brl,
);

export const initEvents = () => {
	cacheStringStore($selectedNetwork, 'billing::selectedNetwork');
	// Load Balances
	sample({
		clock: loadBalances,
		target: loadBalancesFx,
	});
	sample({
		clock: loadBalancesFx.doneData,
		target: $balances,
		fn: (data) => {
			return data.reduce<TUserBalances>((acc, item) => {
				acc[item.currency] = item.availableAmount / 1_000_000_000;
				return acc;
			}, DEFAULT_BALANCES);
		},
	});

	// Load Wallets
	sample({
		clock: loadWallets,
		target: loadWalletsFx,
	});
	sample({
		clock: loadWalletsFx.doneData,
		target: $wallets,
	});
	sample({
		clock: loadWalletsFx.doneData,
		fn: (wallets) => {
			const wallet = wallets.find((wallet) => wallet.default);
			if (wallet) {
				return wallet.currency;
			} else {
				return Currency.TON;
			}
		},
		target: $selectedCurrency,
	});

	// Create Wallet
	$wallets.on(createWalletFx.doneData, (state, wallet) => [...state, wallet]);

	$selectedCurrency.on(selectCurrency, (_, currency) => currency);
	$selectedNetwork.on(selectNetwork, (_, network) => network);
	sample({
		clock: $$availableNetworks,
		target: $selectedNetwork,
		fn: (networks) => networks[0] || '',
	});

	sample({
		source: combine({ currency: $selectedCurrency, network: $selectedNetwork, wallet: $$selectedWallet }),
		clock: $$selectedWallet,
		filter: ({ wallet, currency }) => !wallet?.id && currency !== Currency.BRL,
		target: createWalletFx,
	});

	// Withdraw Crypto
	sample({
		source: combine({ currency: $selectedCurrency, network: $selectedNetwork }),
		clock: createWithdrawalRequestCrypto,
		fn: ({ currency, network }, { amount, address }) => ({
			currency,
			network,
			amount,
			address,
		}),
		target: createWithdrawalRequestCryptoFx,
	});

	sample({ clock: createWithdrawalRequestCryptoFx.doneData, target: withdrawalRequestSuccess });
	sample({ clock: createWithdrawalRequestCryptoFx.failData, target: withdrawalRequestError });

	// Withdraw BRL
	sample({
		clock: createWithdrawalRequestBrazilReal,
		target: createWithdrawalRequestBrazilRealFx,
	});
	sample({ clock: createWithdrawalRequestBrazilRealFx.doneData, target: withdrawalRequestSuccess });
	sample({ clock: createWithdrawalRequestBrazilRealFx.failData, target: withdrawalRequestError });

	$balances.on(withdrawalRequestSuccess, (balances, { currency, balance }) => {
		return {
			...balances,
			[currency]: balance / 1_000_000_000,
		};
	});

	$withdrawalError.on(withdrawalRequestError, (_, error) => error.message);
	$withdrawalError.reset([
		resetWithdrawalError,
		$selectedCurrency.updates,
		$selectedNetwork.updates,
		createWithdrawalRequestCrypto,
		createWithdrawalRequestBrazilReal,
	]);

	sample({ clock: createWithdrawalRequestBrazilRealFx.doneData, target: withdrawalRequestSuccess });
	sample({ clock: createWithdrawalRequestBrazilRealFx.failData, target: withdrawalRequestError });

	// Deposit Cryptobot
	sample({
		clock: createDepositRequestCryptobot,
		target: depositCryptobotFx,
		filter: ({ amount }) => amount > 0,
	});
	sample({
		clock: createDepositRequestCryptobot,
		target: $cryptochillDepositUrl,
		fn: () => null,
	});
	sample({
		clock: depositCryptobotFx.doneData,
		target: $cryptochillDepositUrl,
		fn: ({ link }) => link,
	});

	// Deposit Wallet
	sample({
		clock: createDepositRequestWallet,
		target: depositWalletFx,
		filter: ({ amount }) => amount > 0,
	});
	sample({
		clock: createDepositRequestWallet,
		target: $walletDepositUrl,
		fn: () => null,
	});
	sample({
		clock: depositWalletFx.doneData,
		target: $walletDepositUrl,
		fn: ({ link }) => link,
	});
};
