import { useContext } from 'react';

import LayoutContext, { type LayoutContextType } from './../contexts/LayoutContext';

export default (): LayoutContextType => {
	const context = useContext(LayoutContext);

	if (!context) {
		throw new Error('useGlobalContext must be used within a LayoutProvider');
	}
	return context;
};
