import { sample } from 'effector';

import { Currency } from '@whale-client/dataflow-common';

import { domain } from './domain';
import { loadCurrencyRatesFx, loadHistoryFx } from './effects';
import { loadCurrencyRates, loadCurrencyRatesHistory } from './events';
import { TCurrencyRates, THistoricalCurrencyRates } from './types';

export const $currencyRates = domain.createStore<TCurrencyRates>({});
export const $historicalCurrencyRates = domain.createStore<THistoricalCurrencyRates>({});

export const $$tonCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.TON] || 0);
export const $$btcCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.BTC] || 0);
export const $$notCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.NOT] || 0);
export const $$bnbCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.BNB] || 0);
export const $$mbtcCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.MBTC] || 0);
export const $$cusdCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.CUSD] || 0);
export const $$usdcCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.USDC] || 0);
export const $$usdtCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.USDT] || 0);
export const $$solCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.SOL] || 0);
export const $$ethCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.ETH] || 0);
export const $$celoCurrencyRate = $currencyRates.map((currencyRate) => currencyRate[Currency.CELO] || 0);

export const $$historicalTonCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.TON] || [],
);
export const $$historicalBtcCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.BTC] || [],
);
export const $$historicalNotCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.NOT] || [],
);
export const $$historicalBnbCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.BNB] || [],
);
export const $$historicalMbtcCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.MBTC] || [],
);
export const $$historicalCusdCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.CUSD] || [],
);
export const $$historicalUsdcCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.USDC] || [],
);
export const $$historicalUsdtCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.USDT] || [],
);
export const $$historicalSolCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.SOL] || [],
);
export const $$historicalEthCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.ETH] || [],
);
export const $$historicalCeloCurrencyRates = $historicalCurrencyRates.map(
	(currencyRate) => currencyRate[Currency.CELO] || [],
);

export const initEvents = () => {
	sample({
		clock: loadCurrencyRates,
		target: loadCurrencyRatesFx,
	});
	sample({
		clock: loadCurrencyRatesFx.doneData,
		target: $currencyRates,
	});
	sample({
		clock: loadCurrencyRatesHistory,
		target: loadHistoryFx,
	});
	sample({
		clock: loadHistoryFx.doneData,
		target: $historicalCurrencyRates,
	});
};
