import { Currency } from '@whale-client/dataflow-common';
import { Request } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { TAffiliatedUser } from './types';

export const loadStatsFx = domain.createEffect(async () => {
	const response = await Request.get<{ affiliates: number; commission: number; wagering: number }>('/affiliate/stats');
	return response.data;
});

export const loadWhaleTokenStatsFx = domain.createEffect(async () => {
	const response = await Request.get<{
		currency: Currency;
		availableAmount: number;
		earnedAmount: number;
		totalAmount: number;
	}>('/affiliate/whale-token');
	return response.data;
});

export const loadAffiliatedUsersFx = domain.createEffect(
	async ({ page, perPage }: { page: number; perPage: number }) => {
		const response = await Request.get<{
			data: TAffiliatedUser[];
			page: {
				current: number;
				total: number;
				perPage: number;
			};
		}>('/affiliate/all', {
			params: { page, limit: perPage },
		});
		return response.data;
	},
);
