import BnbIcon from '@/lib/icons/currencies/BNB';
import BrlIcon from '@/lib/icons/currencies/BRL';
import BtcIcon from '@/lib/icons/currencies/BTC';
import CusdIcon from '@/lib/icons/currencies/CUSD';
import NotIcon from '@/lib/icons/currencies/NOT';
import TonIcon from '@/lib/icons/currencies/TON';
import UsdtIcon from '@/lib/icons/currencies/USDT';

export const currenciesIconMap = new Map<string, React.ReactNode>([
	['TON', <TonIcon />],
	['BTC', <BtcIcon />],
	['NOT', <NotIcon />],
	['CUSD', <CusdIcon />],
	['USDT', <UsdtIcon />],
	['BRL', <BrlIcon />],
	['BNB', <BnbIcon />],
]);
