import { Currency } from '@whale-client/dataflow-common';

import { domain } from './domain';

export const loadBalances = domain.createEvent('loadBalances');
export const loadWallets = domain.createEvent('loadWallets');

export const selectCurrency = domain.createEvent<Currency>('selectCurrency');
export const selectNetwork = domain.createEvent<string>('selectNetwork');

export const depositSuccess = domain.createEvent<{ currency: Currency; balance: number }>('depositSuccess');

export const withdrawalRequestSuccess = domain.createEvent<{ currency: Currency; balance: number }>(
	'withdrawalRequestSuccess',
);
export const withdrawalRequestError = domain.createEvent<Error>('withdrawalRequestError');

export const createWithdrawalRequestCrypto = domain.createEvent<{
	amount: number;
	address: string;
}>('createWithdrawalRequestCrypto');

export const resetWithdrawalError = domain.createEvent('resetWithdrawalError');

export const createWithdrawalRequestBrazilReal = domain.createEvent<{
	amount: number;
	taxId: string;
	pixKey: string;
}>('createWithdrawalRequestBrazilReal');

export const depositRequestError = domain.createEvent<Error>('depositRequestError');

export const createDepositRequestCryptobot = domain.createEvent<{
	currency: Currency;
	amount: number;
}>('createDepositRequestCryptobot');

export const createDepositRequestWallet = domain.createEvent<{
	currency: Currency;
	amount: number;
}>('createDepositRequestWallet');
