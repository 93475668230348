import { StrictMode, useEffect } from 'react';

import { I18n } from '@/lib/i18n';
import { LayoutProvider } from '@/src/contexts/LayoutContextProvider';
import { initDataflow } from '@/src/dataflow/initDataflow';
import useTelegram from '@/src/hooks/useTelegram';

initDataflow();

const Languages = async (): Promise<void> => await I18n.setupLanguages();
Languages();

type WrapperProps = {
	children: React.ReactNode;
};

export const Wrapper = ({ children }: WrapperProps) => {
	const { initWebApp } = useTelegram();

	useEffect(() => {
		initWebApp();
	}, []);

	return (
		<StrictMode>
			<LayoutProvider>{children}</LayoutProvider>
		</StrictMode>
	);
};
