import * as React from 'react';
import { SVGProps } from 'react';

const SvgBrl = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
		viewBox="0 0 24 24"
		className="size-4"
		{...props}
	>
		<g clipPath="url(#Brl_svg__a)">
			<path
				fill="#699635"
				d="M23.063 10.05C22.163 4.762 17.55.75 12 .75S1.838 4.763.938 10.05L12 4.5zM.938 13.95c.9 5.288 5.512 9.3 11.062 9.3s10.163-4.012 11.063-9.3L12 19.5z"
			/>
			<path
				fill="#FFE62E"
				d="M12 4.5.938 10.05A11 11 0 0 0 .75 12c0 .675.075 1.313.188 1.95L12 19.5l11.063-5.55c.112-.637.187-1.275.187-1.95s-.075-1.312-.187-1.95z"
			/>
			<path
				fill="#428BC1"
				d="M9.75 10.648c-1.2 0-2.325.263-3.338.713-.037.225-.037.412-.037.637A5.617 5.617 0 0 0 12 17.623c2.1 0 3.938-1.162 4.913-2.85-1.388-2.437-4.088-4.125-7.163-4.125"
			/>
			<path
				fill="#428BC1"
				d="M17.55 12.9c.037-.3.075-.6.075-.9A5.617 5.617 0 0 0 12 6.375c-2.213 0-4.125 1.275-5.062 3.15.9-.263 1.837-.412 2.812-.412 3.188 0 6 1.5 7.8 3.787"
			/>
			<path
				fill="#fff"
				d="M9.75 9.113c-.975 0-1.913.15-2.813.413a5.8 5.8 0 0 0-.525 1.837 8.2 8.2 0 0 1 3.338-.712c3.075 0 5.775 1.65 7.163 4.087.337-.562.524-1.2.637-1.837a9.9 9.9 0 0 0-7.8-3.788"
			/>
			<path
				fill="#fff"
				d="M8.25 12.375a.375.375 0 1 0 0-.75.375.375 0 0 0 0 .75M9.75 14.625a.375.375 0 1 0 0-.75.375.375 0 0 0 0 .75M12 14.625a.375.375 0 1 0 0-.75.375.375 0 0 0 0 .75M12 16.125a.375.375 0 1 0 0-.75.375.375 0 0 0 0 .75M15 14.625a.375.375 0 1 0 0-.75.375.375 0 0 0 0 .75M15 16.125a.375.375 0 1 0 0-.75.375.375 0 0 0 0 .75M13.5 15.375a.375.375 0 1 0 0-.75.375.375 0 0 0 0 .75M8.25 13.875a.375.375 0 1 0 0-.75.375.375 0 0 0 0 .75"
			/>
		</g>
		<defs>
			<clipPath id="Brl_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgBrl;
