type HeaderProps = {
	children: React.ReactNode;
};

type HeaderNamespace = {
	Logo: React.FC<{ children: React.ReactNode }>;
	Menu: React.FC<{ children: React.ReactNode }>;
	Navigation: React.FC<{ children: React.ReactNode }>;
};

const Header: React.FC<HeaderProps> & HeaderNamespace = ({ children }) => {
	return <div className="w-full">{children}</div>;
};

Header.Logo = ({ children }) => {
	return <div className="flex items-center">{children}</div>;
};

Header.Menu = ({ children }) => {
	return <div className="flex items-center py-4">{children}</div>;
};

Header.Navigation = ({ children }) => {
	return <nav className="flex max-h-10 w-full items-center justify-between">{children}</nav>;
};

export default Header;
