import { Currency } from '@whale-client/dataflow-common';

import { domain } from './domain';

import type { Game, GameCode, LiveStats } from './types';

export const loadLatestGames = domain.createEvent<{ games: Game[] }>('loadLatestGames');
export const updateSearchTerm = domain.createEvent<string>('updateSearchTerm');
export const loadLiveGameRtp = domain.createEvent<{ stats: LiveStats }>('loadLiveRtp');
export const loadGameSession = domain.createEvent<{ currency: Currency; gameCode: GameCode }>('loadGameSession');
