import * as React from 'react';

type SvgTrendDownProps = React.SVGProps<SVGSVGElement> & {
	strokeColor?: string;
};

const SvgTrendDown: React.FC<SvgTrendDownProps> = ({ strokeColor = '#FF6659', ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<path
			stroke={strokeColor}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.333}
			d="m12 4-8 8m0 0h5.333M4 12V6.667"
		/>
	</svg>
);

export default SvgTrendDown;
