/* eslint-disable no-console */
import { Currency } from '@whale-client/dataflow-common';
import { Request } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { Games, GameCode, LiveStats } from './types';

export const loadLatestGamesFx = domain.createEffect(async () => {
	const response = await Request.get<{ games: Games }>('/user/games/latest');

	return response.data.games;
});

let currentController: AbortController | null = null;

export const searchGamesFx = domain.createEffect(async (searchTerm: string) => {
	if (currentController && !currentController.signal.aborted) {
		try {
			currentController.abort('not interested anymore');
		} catch (error) {
			console.error('Abort error of search game', error);
		}
	}

	currentController = new AbortController();
	const { signal } = currentController;
	try {
		const response = await Request.get<{ games: Games }>('/game/search', {
			params: {
				name: searchTerm,
			},
			signal,
		});
		return response.data.games;
	} finally {
		currentController = null;
	}
});

export const liveRtpFx = domain.createEffect(async (gameCode: GameCode) => {
	const response = await Request.get<{ stats: LiveStats }>(`/game/${gameCode}/live-rtp`);

	return response.data;
});

export const gamesSessionFx = domain.createEffect(async (data: { currency: Currency; gameCode: GameCode }) => {
	const response = await Request.post<{ url: string }>('/game-session', data);

	return response.data.url;
});
