import * as amplitude from '@amplitude/analytics-browser';
import { useUnit } from 'effector-react';

import { $device } from '@whale-client/dataflow-app';
import { $balances } from '@whale-client/dataflow-billing';
import { $userData } from '@whale-client/dataflow-user';

import debounce from '@/src/utils/debounce';

const aptitudeInstance = amplitude;

const DEBOUNCE_INPUT_TRACKING_MS: number = 300;
const AMPLITUDE_KEY: string = 'd065979e485f07d62e7cd9d2ece34cdd';

type TrackEventName =
	| 'Buy crypto clicked'
	| 'Account restricted gameplay message shown'
	| 'Error'
	| 'Open game'
	| 'View all clicked'
	| 'Reset search'
	| 'Sportsbook Page'
	| 'Terms and Conditions Page'
	| 'Roadmap page'
	| 'Search result clicked'
	| 'Restricted Jurisdictions Page'
	| 'Responsible Gaming Page'
	| `Promotion ${string}`
	| 'Promotions list page'
	| 'Privacy Policy Page'
	| 'Live Casino Page'
	| 'FAQ Page'
	| 'Fairness Page'
	| 'Crypto Games Page'
	| `Playing Game`
	| `Provider page ${string}`
	| 'Engaged banner'
	| 'Casino Page'
	| 'AML Policy Page'
	| 'Wallet opened with unknown tab'
	| 'Buy tab opened'
	| 'Withdraw tab opened'
	| 'Deposit tab opened'
	| 'Settings modal opened'
	| 'Refer modal opened'
	| 'Profile modal opened'
	| 'Welcome modal opened'
	| 'Verification modal opened'
	| 'Authentication modal opened'
	| 'Withdraw funds failed'
	| 'Withdraw funds'
	| 'Set withdrawal amount'
	| 'Set withdrawal percents'
	| 'Set network'
	| 'Set default currency'
	| 'Set currency'
	| 'Express deposit'
	| 'Set deposit address'
	| 'Set buy amount'
	| 'Copy referral link'
	| 'Request withdrawal'
	| 'Navigation - Display 0 balances clicked'
	| 'Navigation - Display in Fiat clicked'
	| 'Navigation - Currency changed'
	| 'Search';

interface EventProps {
	'Buy crypto clicked': {
		fiatAmount: number;
		selectedFiat: string;
		selectedCrypto: string;
	};
	'Account restricted gameplay message shown': {
		message: string;
	};
	Error: {
		type?: string;
		errorMessage?: string;
		error?: Error;
		errorCode?: number;
		data?: unknown;
	};
	'Open game': {
		gameCode: string;
		provider: string;
	};
	'Search result clicked': {
		action: string;
		gameCode: string;
		provider: string;
	};
	'Restricted Jurisdictions Page': undefined;
	'Responsible Gaming Page': undefined;
	'Promotion ${string}': undefined;
	'Promotions list page': undefined;
	'Privacy Policy Page': undefined;
	'Live Casino Page': undefined;
	'FAQ Page': undefined;
	'Fairness Page': undefined;
	'Crypto Games Page': undefined;
	'Playing Game': {
		gameName: string;
	};
	'Provider page ${string}': {
		provider: string;
	};
	'Engaged banner': {
		banner: string;
	};
	'Casino Page': undefined;
	'AML Policy Page': undefined;
	'Wallet opened with unknown tab': undefined;
	'Buy tab opened': undefined;
	'Withdraw tab opened': undefined;
	'Deposit tab opened': undefined;
	'Settings modal opened': undefined;
	'Refer modal opened': undefined;
	'Profile modal opened': undefined;
	'Welcome modal opened': undefined;
	'Verification modal opened': undefined;
	'Authentication modal opened': undefined;
	'Withdraw funds failed': {
		currency: string;
		amount: number;
		message: string;
	};
	'Withdraw funds': {
		currency: string;
		amount: number;
	};
	'Set withdrawal amount': {
		amount: number;
	};
	'Set withdrawal percents': {
		percents: number;
	};
	'Set network': {
		network: string;
	};
	'Set default currency': undefined;
	'Set currency': {
		currency: string;
	};
	'Express deposit': {
		type: string;
		currency: string;
		amount: number;
	};
	'Set deposit address': {
		address: string;
	};
	'Set buy amount': {
		amount: number;
	};
	'Copy referral link': {
		link: string;
	};
	'Request withdrawal': {
		amount: number;
	};
	'Navigation - Display 0 balances clicked': undefined;
	'Navigation - Display in Fiat clicked': undefined;
	'Navigation - Currency changed': {
		currency: string;
		network: string;
		balance: number;
		balanceAmount: number;
	};
	Search: {
		searchValue: string;
		length: number;
	};
}

type TrackEventProps<T extends TrackEventName> = T extends keyof EventProps
	? EventProps[T]
	: Record<string, unknown> | undefined;

const initializeTracking = () => {
	if (location.hostname.includes('staging') || location.hostname.includes('localhost')) {
		return;
	}

	aptitudeInstance.init(AMPLITUDE_KEY, {
		trackingOptions: {
			ipAddress: true,
			language: true,
			platform: true,
		},
		defaultTracking: {
			fileDownloads: false,
		},
	});
};

export default () => {
	const trackEvent = <T extends TrackEventName>(eventName: T, props?: TrackEventProps<T>): void => {
		amplitude.track(eventName, props).promise;
	};

	const trackEventDebounced = debounce(trackEvent, DEBOUNCE_INPUT_TRACKING_MS);

	const setTrackingIdentity = async (id: number) => {
		await amplitude.setUserId(id.toString());
	};

	const device = useUnit($device);
	const user = useUnit($userData);
	const balances = useUnit($balances);

	const setUserProperties = async () => {
		const identify = new amplitude.Identify();

		const setPropertyWithFallback = <T = string>(prop: T | undefined, fallbackProp: T = 'unknown' as T): T => {
			return prop ?? fallbackProp;
		};

		identify
			.set('device', device.toString())
			.set('telegramId', setPropertyWithFallback(user?.telegramId))
			.set('playBlock', setPropertyWithFallback(user?.playBlock))
			.set('termsAccepted', setPropertyWithFallback(user?.termsAccepted))
			.set('telegramUsername', setPropertyWithFallback(user?.telegramUsername))
			.set('mainCurrency', setPropertyWithFallback(user?.mainCurrency))
			.set('clickId', setPropertyWithFallback(user?.clickId))
			.set('affiliateCode', setPropertyWithFallback(user?.affiliateCode))
			.set('affiliateType', setPropertyWithFallback(user?.affiliateType))
			.set('affiliatedByCode', setPropertyWithFallback(user?.affiliateCode))
			.set('serviceLanguage', setPropertyWithFallback(user?.serviceLanguage))
			.set('id', setPropertyWithFallback(user?.id))
			.set('balances', balances);

		await amplitude.identify(identify).promise;
		await amplitude.flush().promise;
	};

	return {
		initializeTracking,
		setTrackingIdentity,
		trackEvent,
		trackEventDebounced,
		setUserProperties,
	};
};
