import { combine, sample } from 'effector';

import { cacheJsonStore } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { loadConnectionTokenFx, initializeConnectionFx } from './effects';
import {
	loadConnectionToken,
	initNotificationChannel,
	addChannelMessage,
	readNotification,
	readAllNotifications,
} from './events';
import { Notification } from './types';

const $connectionToken = domain.store<string>('');
const $notificationsSubscriptionToken = domain.store<string>('');
const $userId = domain.store<number | null>(null);

const $notifications = domain.store<Notification[]>([]);
export const $readNotificationsIds = domain.store<Notification['id'][]>([]);
const $hiddenNotificationsIds = domain.store<Notification['id'][]>([]);

export const $$notificationsToDisplay = combine(
	$notifications,
	$readNotificationsIds,
	$hiddenNotificationsIds,
	(notifications, readIds, hiddenIds) => {
		return notifications
			.filter((notification) => !hiddenIds.includes(notification.id))
			.filter((notification) => ['freespin', 'cashback', 'notification'].includes(notification.type))
			.map((notification) => ({
				...notification,
				read: readIds.includes(notification.id),
			}));
	},
);

export const $$countNewNotifications = combine(
	$$notificationsToDisplay,
	$readNotificationsIds,
	(notifications, readIds) => {
		return notifications.filter((notification) => !readIds.includes(notification.id)).length;
	},
);

export const initEvents = () => {
	cacheJsonStore($connectionToken, 'ws:connectionToken');
	cacheJsonStore($notificationsSubscriptionToken, 'ws:notificationsSubscriptionToken');
	cacheJsonStore($readNotificationsIds, 'ws:readNotificationsIds');
	cacheJsonStore($hiddenNotificationsIds, 'ws:hiddenNotificationsIds');

	sample({
		clock: readAllNotifications,
		source: $notifications,
		fn: (notifications) => notifications.map(({ id }) => id),
		target: $readNotificationsIds,
	});
	$readNotificationsIds.on(readNotification, (ids, id) => [...ids, id]);
	$notifications.on(addChannelMessage, (messages, message) => [
		...messages.filter((oldMessage) => oldMessage.id !== message.id),
		message,
	]);
	// Load connection token
	sample({
		clock: loadConnectionToken,
		target: loadConnectionTokenFx,
	});
	sample({
		clock: loadConnectionTokenFx.doneData,
		fn: ({ token }) => token,
		target: $connectionToken,
	});
	sample({
		clock: loadConnectionTokenFx.doneData,
		fn: ({ token }) => token,
		target: $connectionToken,
	});
	sample({
		clock: loadConnectionTokenFx.doneData,
		fn: ({ notificationsToken }) => notificationsToken,
		target: $notificationsSubscriptionToken,
	});
	sample({
		source: { connectionToken: $connectionToken, subscriptionToken: $notificationsSubscriptionToken, userId: $userId },
		clock: loadConnectionTokenFx.doneData,
		target: initializeConnectionFx,
	});

	$userId.on(initNotificationChannel, (state, { userId }) => userId);

	sample({
		source: { connectionToken: $connectionToken, subscriptionToken: $notificationsSubscriptionToken, userId: $userId },
		clock: initNotificationChannel,
		filter: ({ userId, subscriptionToken, connectionToken }) => !!userId && !!subscriptionToken && !!connectionToken,
		target: initializeConnectionFx,
	});

	sample({
		source: [$connectionToken, $notificationsSubscriptionToken],
		clock: initNotificationChannel,
		filter: (tokens) => !tokens.every(Boolean),
		target: loadConnectionToken,
	});
};
