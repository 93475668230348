import { useUnit } from 'effector-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { clientOnly } from 'vike-react/clientOnly';

import { $$tonCurrencyRate } from '@whale-client/dataflow-currency-rates';

import { AdultsOnlyBanner } from '@/components/Footer/components/AdultsOnlyBanner';
import { BitcoinBanner } from '@/components/Footer/components/BitcoinBanner';
import { CeloBanner } from '@/components/Footer/components/CeloBanner';
import { TetherBanner } from '@/components/Footer/components/TetherBanner';
import { TonBanner } from '@/components/Footer/components/TonBanner';
import { WhaleGamesBanner } from '@/components/Footer/components/WhaleGamesBanner';
import { Separator } from '@/components/ui/separator';
import Link from '@/lib/components/Link';
import { cn } from '@/lib/utils';

const SupportLink = clientOnly(() => import('@/components/Footer/components/SupportLink'));
const LanguageChangeDropdown = clientOnly(() => import('@/components/Footer/components/LanguageChangeDropdown'));

const Wrapper = ({ children }: { children: ReactNode }) => (
	<footer className="flex flex-col gap-4 pb-24 pt-4">{children}</footer>
);

const Text = ({ children, className }: { children: ReactNode; className?: string }) => (
	<p className={cn('text-xs text-brandblue-200', className)}>{children}</p>
);

const Section = ({ children, className }: { children: ReactNode; className?: string }) => (
	<div className={cn('flex w-full items-center justify-center', className)}>{children}</div>
);
const Links = ({ children }: { children: ReactNode }) => {
	return (
		<div className="flex flex-wrap items-center justify-center gap-1 pb-2 text-xs text-brandblue-200">{children}</div>
	);
};

const Divider = () => <Separator className="w-full" />;

export const Footer = () => {
	const { t } = useTranslation();

	const tonCurrencyRate = useUnit($$tonCurrencyRate);
	return (
		<Wrapper>
			<Divider />
			<Section className="flex-col gap-2">
				<Text>
					{t('footer_allRightsReserved')} - © Whale Games
					<span className="text-[0.60rem]">[v2.1.3]</span>
				</Text>
				<Text className="font-bold">1 TON = ${tonCurrencyRate.toFixed(2)}</Text>
				<Links>
					<Link href="/terms-and-conditions">{t('footer_termsAndConditions')}</Link> -{' '}
					<Link href="/responsible-gaming">{t('footer_responsibleGaming')}</Link> -{' '}
					<a href="/aml-policy">{t('footer_amlPolicy')}</a> -
					<Link href="/restricted-jurisdictions">{t('footer_restrictedJurisdictions')}</Link> -{' '}
					<a href="/fairness">{t('footer_fairness')}</a> -<Link href="/faq">{t('footer_faq')}</Link> -{' '}
					<SupportLink url="https://t.me/whalegamessupport_bot">{t('footer_support')}</SupportLink>
				</Links>
				<LanguageChangeDropdown />
			</Section>
			<Divider />
			<Section className="flex-wrap gap-4">
				<TetherBanner />
				<TonBanner />
				<BitcoinBanner />
				<CeloBanner />
			</Section>
			<Divider />
			<Section className="flex-col">
				<WhaleGamesBanner />
				<AdultsOnlyBanner />
			</Section>
			<Divider />
			<Section className="flex-col">
				<Text className="text-center">
					Whale.io is owned and operated by YGF Entertainment B.V. (Korporaalweg 10, Willemstad, Curaçao). It is
					licensed and regulated by the Government of Curaçao under the gaming license 8048/JAZ
				</Text>
			</Section>
		</Wrapper>
	);
};
