import * as React from 'react';
const SvgNot = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" {...props}>
		<path fill="#fff" d="M10 19.999c5.523 0 10-4.477 10-10s-4.478-10-10-10S0 4.478 0 10s4.478 10 10 10" />
		<path
			fillRule="evenodd"
			d="M6.783 14.448h6.482a1.6 1.6 0 0 0 .698-.146c.287-.133.44-.344.546-.5l.024-.038q.189-.335.19-.723c0-.247-.059-.515-.19-.749l-.004-.007-4.095-7.035a.503.503 0 0 0-.87.003l-4.022 7.02-.003.006c-.092.152-.234.386-.26.689a1.37 1.37 0 0 0 .784 1.352c.271.127.546.128.72.128m2.713-1.005H6.783c-.179 0-.247-.011-.295-.034a.36.36 0 0 1-.207-.359c.004-.043.021-.092.124-.261l.006-.01L9.497 7.39zm1.005 0V7.365l3.157 5.422c.035.065.06.158.06.254a.45.45 0 0 1-.052.214 1 1 0 0 1-.08.102.2.2 0 0 1-.047.035.6.6 0 0 1-.274.05z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgNot;
