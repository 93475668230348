import GameInfo, { type LiveStats } from '@/lib/components/GameInformation';

const GameInformation = () => {
	const stats: LiveStats = {
		day: { rtp: 96, trend: 'down' },
		week: { rtp: 95, trend: 'down' },
		month: { rtp: 97, trend: 'up' },
	};

	return (
		<GameInfo>
			<GameInfo.GameCard
				name="Sample Game"
				providerName="Sample Provider"
				providerLabel="Label"
				image={<img src="/path/to/image.jpg" alt="Game Image" />}
			/>
			<GameInfo.GameStats>
				<GameInfo.LiveRTP stats={stats} dayLabel="24H" weekLabel="Week" monthLabel="Month" notAvailableLabel="N/A" />
				<GameInfo.GameStat label="RTP" value="?%" className="rounded-t-lg bg-primary" />
				<GameInfo.GameStat label="Volatility" value="N/A" className="rounded-b-lg bg-primary" />
			</GameInfo.GameStats>
		</GameInfo>
	);
};

export default GameInformation;
