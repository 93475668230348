type LinkProps = {
	href?: string;
	onClick?: () => void;
	children: React.ReactNode;
};

export default ({ href = '#', children, onClick }: LinkProps) => {
	return (
		<a href={href} onClick={onClick}>
			{children}
		</a>
	);
};
