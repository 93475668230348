import React, { useState, useCallback, type ReactNode } from 'react';
import { redirect, render } from 'vike/abort';
import { navigate } from 'vike/client/router';
import { usePageContext } from 'vike-react/usePageContext';

import LayoutContext from './LayoutContext';

import useTelegram from '@/src/hooks/useTelegram';
import {
	CASINO_ROUTE,
	CRYPTO_CASINO_ROUTE,
	HOMEPAGE_ROUTE,
	FUTURES_ROUTE,
	LIVE_CASINO_ROUTE,
	PROMOTIONS_ROUTE,
	ROADMAP_ROUTE,
	SPORTSBOOK_ROUTE,
	EARN_ROUTE,
	PROFILE_ROUTE,
	WALLET_ROUTE,
} from '@/src/utils/routes';

interface LayoutProviderProps {
	children: ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
	const { successHapticFeedback } = useTelegram();
	const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(false);
	const [isGameMode, setIsGameMode] = useState<boolean>(false);
	const [isCompactMode, setIsCompactMode] = useState<boolean>(false);
	const [isCurrencyMenuVisible, setIsCurrencyMenuVisible] = useState<boolean>(false);
	const [isBalanceVisible, setIsBalanceVisible] = useState<boolean>(false);
	const [isProfileModalOpened, setIsProfileModalOpened] = useState<boolean>(false);
	const [isEarnModalOpened, setIsEarnModalOpened] = useState<boolean>(false);
	const [isGameInformationOpened, setIsGameInformationOpened] = useState<boolean>(false);
	const [isWalletModalOpened, setIsWalletModalOpened] = useState<boolean>(false);
	const [isSettingsModalOpened, setIsSettingsModalOpened] = useState<boolean>(false);
	const [isGeoBlockOpened, setIsGeoBlockOpened] = useState<boolean>(false);
	const [isPlayBlockOpened, setIsPlayBlockOpened] = useState<boolean>(false);
	const [isStickyMenuHidden, hideStickyMenu] = useState<boolean>(false);

	const toggleSidebar = useCallback(() => {
		setIsSidebarOpened((opened) => !opened);
	}, []);

	const closeSidebar = useCallback(() => {
		setIsSidebarOpened(false);
	}, []);

	const toggleProfileModal = useCallback(() => {
		closeSidebar();
		setIsProfileModalOpened((opened) => !opened);
	}, [closeSidebar]);

	const toggleEarnModal = useCallback(() => {
		closeSidebar();
		setIsEarnModalOpened((opened) => !opened);
	}, [closeSidebar]);

	const toggleWalletModal = useCallback(() => {
		closeSidebar();
		setIsWalletModalOpened((opened) => !opened);
	}, []);

	const toggleGeoBlock = useCallback(() => {
		setIsGeoBlockOpened((opened) => !opened);
	}, []);

	const togglePlayBlock = useCallback(() => {
		setIsPlayBlockOpened((opened) => !opened);
	}, []);

	const toggleSettingsModal = useCallback(() => {
		setIsSettingsModalOpened((opened) => !opened);
	}, []);

	const toggleBalance = useCallback(() => {
		setIsBalanceVisible((opened) => !opened);
	}, []);

	const setGameInformationView = useCallback((value: boolean) => {
		setIsGameInformationOpened((opened) => !opened);
	}, []);

	const toggleStickyMenu = useCallback((value: boolean) => {
		hideStickyMenu(value);
	}, []);

	const navigateTo = (path?: string) => {
		if (isSidebarOpened) {
			closeSidebar();
		}

		window.location.href = `${path}`;
		// navigate(`/${path}`);
	};

	const enableGameMode = useCallback(() => {
		setIsGameMode(true);
	}, []);

	const disableGameMode = useCallback(() => {
		setIsGameMode(false);
	}, []);

	const enableCompactMode = useCallback(() => {
		setIsCompactMode(true);
	}, []);

	const disableCompactMode = useCallback(() => {
		setIsCompactMode(false);
	}, []);

	const goBack = useCallback(() => {
		successHapticFeedback();
		window.history.go(-1);
	}, []);

	const navigateToHomepage = useCallback(() => {
		navigateTo(HOMEPAGE_ROUTE);
	}, []);

	const navigateToCasino = useCallback(() => {
		navigateTo(CASINO_ROUTE);
	}, []);

	const navigateToSportsbook = useCallback(() => {
		navigateTo(SPORTSBOOK_ROUTE);
	}, []);

	const navigateToPromotions = useCallback(() => {
		navigateTo(PROMOTIONS_ROUTE);
	}, []);

	const navigateToPromotion = useCallback((promotionSlug: string) => {
		navigateTo(`${PROMOTIONS_ROUTE}/${promotionSlug}`);
	}, []);

	const navigateToEarn = useCallback(() => {
		navigateTo(EARN_ROUTE);
	}, []);

	const navigateToGame = useCallback((gameProviderSlug: string, gameSlug: string) => {
		successHapticFeedback();
		navigateTo(`/game-provider/${gameProviderSlug}/${gameSlug}`);
	}, []);

	const navigateToGameProvider = useCallback((gameProviderSlug: string) => {
		navigateTo(`/game-provider/${gameProviderSlug}`);
	}, []);

	const navigateToProfile = useCallback(() => {
		navigateTo(PROFILE_ROUTE);
	}, []);

	const navigateToLiveCasino = useCallback(async () => {
		closeSidebar();
		await navigateTo(LIVE_CASINO_ROUTE);
	}, []);

	const navigateToCryptoGames = useCallback(() => {
		closeSidebar();
		navigateTo(CRYPTO_CASINO_ROUTE);
	}, []);

	const navigateToRoadmap = useCallback(() => {
		navigateTo(ROADMAP_ROUTE);
	}, []);

	const navigateToWallet = useCallback(() => {
		navigateTo(WALLET_ROUTE);
	}, []);

	const navigateToUpAndDown = useCallback(() => {
		successHapticFeedback();
		navigateTo(FUTURES_ROUTE);
	}, []);

	const navigateToWhalesChoiceBanner = useCallback(() => {
		successHapticFeedback();
		navigateTo(FUTURES_ROUTE);
	}, []);

	const contextValue = {
		isSidebarOpened,
		isProfileModalOpened,
		isEarnModalOpened,
		isWalletModalOpened,
		isGeoBlockModalOpened: isGeoBlockOpened,
		isPlayBlockModalOpened: isPlayBlockOpened,
		isGameMode,
		isCompactMode,
		isPlayBlockOpened,
		isSettingsModalOpened,
		isBalanceHidden: isBalanceVisible,
		isStickyMenuHidden,
		isGameInformationViewOpened: isGameInformationOpened,
		setGameInformationView,
		toggleStickyMenu,
		goBack,
		enableGameMode,
		enableCompactMode,
		disableCompactMode,
		disableGameMode,
		toggleBalance,
		toggleSidebar,
		closeSidebar,
		toggleProfileModal,
		togglePlayBlock,
		toggleGeoBlock,
		toggleEarnModal,
		toggleWalletModal,
		toggleSettingsModal,
		navigateTo,
		navigateToHomepage,
		navigateToCasino,
		navigateToLiveCasino,
		navigateToCryptoGames,
		navigateToSportsbook,
		navigateToEarn,
		navigateToPromotion,
		navigateToPromotions,
		navigateToGame,
		navigateToGameProvider,
		navigateToProfile,
		navigateToRoadmap,
		navigateToWallet,
		navigateToUpAndDownTurbo: navigateToUpAndDown,
		navigateToWhalesChoiceBanner,
	};

	return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
};
