import { Centrifuge, State } from 'centrifuge';

import { Request } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { addChannelMessage, loadConnectionToken } from './events';
import { Notification } from './types';

export const loadConnectionTokenFx = domain.createEffect(async () => {
	const response = await Request.get<{ token: string; notificationsToken: string }>('/auth/socket-public');
	return response.data;
});

let connection: Centrifuge;

export const claimCashbackFx = domain.createEffect(async (cashbackId: number) => {
	await Request.post(`/cashback/${cashbackId}`);
});

export const claimFreeSpinFx = domain.createEffect(async (cashbackId: number) => {
	await Request.post(`/bonus/${cashbackId}`);
});

export const initializeConnectionFx = domain.createEffect(
	async ({
		connectionToken,
		userId,
		subscriptionToken,
	}: {
		connectionToken: string;
		userId: number | null;
		subscriptionToken: string;
	}) => {
		if (!userId) {
			return null;
		}
		if (!connectionToken) {
			return null;
		}
		if (!connectionToken) {
			return null;
		}
		if (connection) {
			// eslint-disable-next-line no-console
			console.log('Recreating connection');
			connection.disconnect();
		}
		try {
			connection = new Centrifuge(`${window.env.PUBLIC_SOCKET_URL}/connection/websocket`, {
				token: connectionToken,
			});
			connection.on('error', (error) => {
				// eslint-disable-next-line no-console
				console.error('WebSocket connection error', error);
				if (error?.error?.code === 109 && error?.error?.message === 'token expired') {
					loadConnectionToken();
				}
			});

			if (connection.state === State.Disconnected) {
				connection.connect();
			}

			await connection.ready();

			const subscription = connection.newSubscription(`user:${userId}`, { token: subscriptionToken });

			subscription.on('subscribed', async () => {
				try {
					const history = await subscription.history({ limit: 100 });
					if (history && history?.publications?.length) {
						for (const item of history.publications) {
							addChannelMessage(item.data as Notification);
						}
					}
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error('Failed to get history', e);
				}
			});

			subscription.on('publication', async (ctx) => {
				addChannelMessage(ctx.data as Notification);
			});

			subscription.subscribe();
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('Failed to connect to websocket', e);
		}
	},
);
