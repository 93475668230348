import { sample } from 'effector';

import { initEvents as initAffiliateEvents } from '@whale-client/dataflow-affiliate';
import { $device, Device, initEvents as initAppEvents } from '@whale-client/dataflow-app';
import { initEvents as initBillingEvents, loadBalances } from '@whale-client/dataflow-billing';
import { $selectedCurrency } from '@whale-client/dataflow-billing';
import { initEvents as initChat } from '@whale-client/dataflow-chat';
import { initEvents as initCurrencyEvents, loadCurrencyRates } from '@whale-client/dataflow-currency-rates';
import { initEvents as initGamesEvents } from '@whale-client/dataflow-games';
import { initEvents as initNotifications, initNotificationChannel } from '@whale-client/dataflow-notifications';
import { initEvents as initTransactions } from '@whale-client/dataflow-transactions';
import {
	$$isAuthenticated,
	$tgPlatform,
	$userLanguage,
	initEvents as initUserEvents,
	initTg,
	loadUserData,
	loadUserGeo,
	$$mainCurrency,
	signInSuccess,
	$$userId,
} from '@whale-client/dataflow-user';
import { initCache } from '@whale-client/dataflow-utils';
import { setupRequest, setupLegacyRequest } from '@whale-client/dataflow-utils';

const registerDataFlow = (initFunction: () => void) => {
	initFunction();
};

export const initDataflow = () => {
	setupRequest({
		$device,
		$userLanguage,
		$tgPlatform,
	});
	setupLegacyRequest({
		$device,
		$userLanguage,
		$tgPlatform,
	});

	registerDataFlow(initAppEvents);
	registerDataFlow(initUserEvents);
	registerDataFlow(initCurrencyEvents);
	registerDataFlow(initGamesEvents);
	registerDataFlow(initAffiliateEvents);
	registerDataFlow(initBillingEvents);
	registerDataFlow(initChat);
	registerDataFlow(initTransactions);

	registerDataFlow(initNotifications);
	initCache();

	loadUserGeo();

	loadCurrencyRates();

	$selectedCurrency.on($$mainCurrency.updates, (selected, main) => main || selected);

	$$isAuthenticated.subscribe((isAuthenticated) => {
		if (isAuthenticated) {
			loadBalances();

			const userId = $$userId.getState();
			if (userId) {
				// eslint-disable-next-line no-console
				console.log('initNotificationChannel');
				initNotificationChannel({
					userId,
				});
			}
		}
	});
	let deviceSubscription: ReturnType<typeof $device.subscribe>;
	// eslint-disable-next-line prefer-const
	deviceSubscription = $device.subscribe((device) => {
		if (device === Device.UNKNOWN) {
			return;
		}
		if (device === Device.TELEGRAM) {
			initTg();
			deviceSubscription?.unsubscribe();
			return;
		}
		sample({
			clock: signInSuccess,
			target: loadUserData,
		});
		loadUserData();
		deviceSubscription?.unsubscribe();
	});
};
