import { Currency } from '@whale-client/dataflow-common';
import { Request } from '@whale-client/dataflow-utils';

import { domain } from './domain';
import { TWallet, WithdrawalError } from './types';

export const loadBalancesFx = domain.createEffect(async () => {
	const response = await Request.get<{ currency: Currency; availableAmount: number }[]>('/balance');
	return response.data;
});

export const loadWalletsFx = domain.createEffect(async () => {
	const response = await Request.get<TWallet[]>('/wallets');
	return response.data;
});

export const createWalletFx = domain.createEffect(
	async ({ currency, network }: { currency: Currency; network: string }) => {
		const response = await Request.post<TWallet>('/wallets/create', {
			network,
			currency,
		});
		return response.data;
	},
);

export const createWithdrawalRequestCryptoFx = domain.createEffect(
	async ({
		amount,
		address,
		currency,
		network,
	}: {
		amount: number;
		address: string;
		currency: Currency;
		network: string;
	}) => {
		const response = await Request.post<{ balance: number; error: undefined } | { balance: undefined; error: string }>(
			'/withdraw/request',
			{
				amount: amount * 1_000_000_000,
				address,
				currency,
				network,
			},
		);
		checkResponseStatus(response);
		const balance = response.data.balance;
		const error = response.data.error;
		if (error || typeof balance === 'undefined') {
			throw new WithdrawalError(error || 'Unknown error');
		}
		return { currency, balance };
	},
);

export const createWithdrawalRequestBrazilRealFx = domain.createEffect(
	async ({ amount, taxId, pixKey }: { amount: number; taxId: string; pixKey: string }) => {
		const response = await Request.post<{ balance: number; error: undefined } | { balance: undefined; error: string }>(
			'/pix/withdraw',
			{
				amount: amount * 1_000_000_000,
				tax_id: taxId,
				pix_key: pixKey,
			},
		);
		checkResponseStatus(response);
		const balance = response.data.balance;
		const error = response.data.error;
		if (error || typeof balance === 'undefined') {
			throw new WithdrawalError(error || 'Unknown error');
		}
		return { currency: Currency.BRL, balance };
	},
);

export const depositCryptobotFx = domain.createEffect(
	async ({ currency, amount }: { currency: Currency; amount: number }) => {
		const response = await Request.post<{ link: string }>('/deposit/cryptobot', {
			amount,
			currency,
		});
		return response.data;
	},
);

export const depositWalletFx = domain.createEffect(
	async ({ currency, amount }: { currency: Currency; amount: number }) => {
		const response = await Request.post<{ link: string }>('/deposit/wallet', {
			amount,
			currency,
		});
		return response.data;
	},
);

function checkResponseStatus<T>(response: Awaited<ReturnType<typeof Request.post<T>>>) {
	if (response.status >= 500) {
		throw new WithdrawalError(response.statusText || 'Server error');
	}
	if (response.status >= 400) {
		throw new WithdrawalError(response.statusText || 'Request error');
	}
}
