import axios from 'axios';
import { combine, sample, Store, createEffect } from 'effector';

export const LegacyRequest = axios.create({
	baseURL: typeof window !== 'undefined' ? (window as any).env.PUBLIC_LEGACY_API : undefined,
	headers: { 'Content-Type': 'application/json' },
});

interface LegacyRequestFnParams {
	device: string;
	lang: string;
	platform: string;
}

export const setupLegacyRequest = ({
	$device,
	$userLanguage,
	$tgPlatform,
}: {
	$device: Store<string>;
	$userLanguage: Store<string>;
	$tgPlatform: Store<string>;
}) => {
	const $$data = combine($device, $userLanguage, $tgPlatform, (device, lang, platform) => ({
		device,
		lang,
		platform,
	}));

	sample({
		clock: [$$data],
		target: createEffect(({ device, lang, platform }: LegacyRequestFnParams) => {
			LegacyRequest.interceptors.request.use((config) => {
				config.headers['X-Device'] = device;
				config.headers['X-Lang'] = lang;
				config.headers['X-Platform'] = platform;
				return config;
			});
		}),
	});
};
