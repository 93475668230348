import React, { createContext, useContext, useState, ReactNode } from 'react';

import { Currency } from '@whale-client/dataflow-common';

import ChevronDown from './../icons/ChevronDown';

import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

type CurrencyMenuProps = {
	children: React.ReactNode;
	initialCurrency?: string;
	currencyIcons: Map<string, React.ReactNode>;
};

const CurrencyMenuContext = createContext<CurrencyMenuContextType | undefined>(undefined);

const useCurrencyMenu = (): CurrencyMenuContextType => {
	const context = useContext(CurrencyMenuContext);

	if (!context) {
		throw new Error('useCurrencyMenu must be used within a CurrencyMenuProvider');
	}

	return context;
};

type CurrencyMenuProviderProps = {
	children: ReactNode;
	initialCurrency?: string;
	currencyIcons: Map<string, React.ReactNode>;
};

export const CurrencyMenuProvider: React.FC<CurrencyMenuProviderProps> = ({ children }) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	return (
		<CurrencyMenuContext.Provider
			value={{
				isExpanded,
				setIsExpanded,
			}}
		>
			{children}
		</CurrencyMenuContext.Provider>
	);
};

interface CurrencyMenuContextType {
	isExpanded: boolean;
	setIsExpanded: (expanded: boolean) => void;
}

interface CurrencyMenuNamespace {
	SelectedValue: React.FC<SelectedValueProps>;
	TriggerCallToAction: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & TriggerCallToActionProps>;
	ExpandedMenu: React.FC<ExpandedMenuProps>;
	MenuItems: React.FC<MenuItemsProps>;
	MenuItem: React.FC<MenuItemProps>;
	BottomMenu;
	CurrencyOption: React.FC<CurrencyOptionProps>;
	ButtonOption: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & TriggerCallToActionProps>;
	ToggleOption: React.FC<ToggleOptionProps>;
	Separator: React.FC;
}

type SelectedValueProps = {
	currency: Currency;
	amount: string;
	icon: ReactNode;
};

type TriggerCallToActionProps = {
	children: React.ReactNode;
	onClick: () => void;
	'aria-label': string;
};

type ExpandedMenuProps = {
	children: React.ReactNode;
};

type MenuItemsProps = {
	children: React.ReactNode;
};

type MenuItemProps = {
	children: React.ReactNode;
};

type CurrencyOptionProps = {
	children: string;
	value: string;
	icon: React.ReactNode;
	amount: string;
	onClick: (value: Currency) => void;
};

type ButtonOptionProps = {
	children: React.ReactNode;
	onClick: () => void;
};

interface ToggleOptionProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	active: boolean;
	onToggle: () => void;
}

const CurrencyMenu: React.FC<CurrencyMenuProps> & CurrencyMenuNamespace = ({ children, currencyIcons }) => {
	return <CurrencyMenuProvider currencyIcons={currencyIcons}>{children}</CurrencyMenuProvider>;
};

CurrencyMenu.SelectedValue = ({ amount, currency, icon }: SelectedValueProps) => {
	const { setIsExpanded } = useCurrencyMenu();

	const handleClick = () => {
		setIsExpanded(true);
	};

	return (
		<div
			role="region"
			className="flex cursor-pointer items-center gap-x-1 py-2 pl-2.5 pr-0.5 md:gap-x-2.5 md:py-0 md:pl-[0.890rem]"
			onClick={handleClick}
		>
			<div className="flex items-center gap-x-2 md:gap-x-2.5">
				{amount && <span className="text-[0.85em] font-medium text-white">{amount}</span>}
				<div className="flex items-center md:gap-x-1">
					{icon}
					<button className="px-1" aria-label="Expand currency">
						<ChevronDown />
					</button>
				</div>
			</div>
		</div>
	);
};

CurrencyMenu.TriggerCallToAction = ({ children, ...props }: TriggerCallToActionProps) => {
	return (
		<button
			className="inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-md border-1 border-solid border-brandblue-300 bg-gradient-primary px-4 py-2 text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 active:opacity-90 disabled:pointer-events-none disabled:opacity-50 xxs:h-7 xxs:min-w-7 xxs:rounded-sm xxs:px-[0.24rem] xxs:py-[0.21rem] sm:h-9 sm:min-w-9 sm:rounded-lg sm:px-[0.7rem] sm:py-[0.45rem]"
			{...props}
		>
			{children}
		</button>
	);
};

CurrencyMenu.ExpandedMenu = ({ children }: ExpandedMenuProps) => {
	const { isExpanded, setIsExpanded } = useCurrencyMenu();

	if (!isExpanded) return null;

	const handleClose = () => {
		setIsExpanded(false);
	};

	return (
		<div className="fixed inset-0 z-10" onClick={handleClose}>
			<div
				className="rounded-[10px]] absolute inset-x-4 top-16 z-20 flex w-auto flex-col gap-y-2 bg-primary p-2 md:left-auto"
				onClick={(e) => e.stopPropagation()}
			>
				{children}
			</div>
		</div>
	);
};

CurrencyMenu.MenuItems = ({ children }: MenuItemsProps) => {
	return <div className="flex flex-col gap-y-2">{children}</div>;
};

CurrencyMenu.MenuItem = ({ children }: MenuItemProps) => {
	return <div className="flex flex-col gap-y-2">{children}</div>;
};

CurrencyMenu.CurrencyOption = ({ value, icon, amount, onClick }: CurrencyOptionProps) => {
	const { setIsExpanded } = useCurrencyMenu();

	const handleClick = () => {
		setIsExpanded(false);

		onClick(value as Currency);
	};

	return (
		<button
			className="flex w-full items-center justify-between gap-12 rounded-[6px] px-3 py-2 text-left hover:bg-secondary focus:bg-secondary"
			onClick={handleClick}
			aria-label={`Currency Menu Option ${amount}`}
		>
			<span className="text-[0.85em] font-medium text-white">{amount}</span>
			<div className="flex items-center gap-x-[0.45rem]">
				<span className="text-[0.85em] font-semibold text-white">{value.toUpperCase()}</span>
				{icon}
			</div>
		</button>
	);
};

CurrencyMenu.ButtonOption = ({ children, onClick, ...props }: ButtonOptionProps) => {
	return (
		<button
			className="flex w-full items-center justify-between gap-12 rounded-xl px-3 py-2 text-left text-white hover:bg-secondary focus:bg-secondary"
			onClick={onClick}
			{...props}
		>
			{children}
		</button>
	);
};

CurrencyMenu.BottomMenu = ({ children }) => {
	return <div className="flex flex-col gap-y-6 pb-3">{children}</div>;
};

CurrencyMenu.ToggleOption = ({ children, active, onToggle, ...props }: ToggleOptionProps) => {
	return (
		<div className={'flex justify-between px-3'}>
			<span className="text-[0.85em] font-medium text-white">{children}</span>
			<button
				className={cn('inline-flex h-5 w-9 items-center justify-start gap-2.5 rounded-2xl bg-gray-800 p-0.5', {
					'bg-indigo-500': active,
				})}
				onClick={() => onToggle()}
				{...props}
			>
				<div
					className={cn('size-4 rounded-full bg-gray-900 transition-transform', {
						'translate-x-4 bg-white': active,
						'translate-x-0': !active,
					})}
				></div>
			</button>
		</div>
	);
};

CurrencyMenu.Separator = () => {
	return <Separator />;
};

export default CurrencyMenu;
