import { Request } from '@whale-client/dataflow-utils';
import { domain } from '../domain';

export const signInMiniPayFx = domain.createEffect(async () => {
	const { BrowserProvider } = await import('ethers');
	// @ts-ignore
	const provider = window?.ethereum;
	if (!provider) {
		throw new Error('No provider provided');
	}

	// @ts-ignore
	const ethersProvider = new BrowserProvider(provider);
	const signer = await ethersProvider.getSigner();
	const getOtpResponse = await Request.patch<{ otp: string; message: string }>('/auth/mini-pay/otp', {
		address: signer.address,
	});
	const { otp, message } = getOtpResponse.data;

	const signature = await signer.signMessage(message);

	await Request.put('/auth/mini-pay/otp', {
		otp,
		signature,
	});
});
