import { useUnit } from 'effector-react';
import { t } from 'i18next';
import * as React from 'react';

import { $balances, $$availableCurrencies } from '@whale-client/dataflow-billing';
import { Currency } from '@whale-client/dataflow-common';
import { $currencyRates, loadCurrencyRates } from '@whale-client/dataflow-currency-rates';
import { $$mainCurrency, setMainCurrency } from '@whale-client/dataflow-user';
import useLayoutContext from '../hooks/useLayoutContext';
import formatNumber from '../utils/formatNumber';

import CurrencyMenu from '@/lib/components/CurrencyMenu';
import useTracking from '@/src/hooks/useTracking';
import { currenciesIconMap } from '@/src/utils/getCurrenciesIconMap';

const CurrencyMenuComponent = ({ onBuyCryptoClicked }) => {
	const { trackEvent } = useTracking();
	const { isBalanceHidden } = useLayoutContext();

	const mainCurrency = useUnit($$mainCurrency);
	const availableCurrencies = useUnit($$availableCurrencies);
	const handleLoadCurrencyRates = useUnit(loadCurrencyRates);
	const balances = useUnit($balances);
	const currencyRates = useUnit($currencyRates);
	const handleSetMainCurrency = useUnit(setMainCurrency);
	const [hideZeroBalances, setHideZeroBalances] = React.useState(false);
	const [displayUsd, setDisplayUsd] = React.useState(false);

	const availableBalance = balances[mainCurrency] || 0;

	const onCurrencySelected = (currency: Currency) => {
		if (currency === mainCurrency) {
			trackEvent('Set default currency');

			return;
		}

		trackEvent('Set currency', { currency });

		handleSetMainCurrency(currency);
	};

	const onHideZeroBalancesToggled = () => {
		trackEvent('Navigation - Display 0 balances clicked');

		setHideZeroBalances(!hideZeroBalances);
	};

	const onDisplayInUSDToggled = () => {
		trackEvent('Navigation - Display in Fiat clicked');
		if (!displayUsd && currencyRates[mainCurrency] === undefined) {
			handleLoadCurrencyRates();
		}
		setDisplayUsd(!displayUsd);
	};

	const getVisibleCurrencies = () => {
		if (availableCurrencies.length === 0 && !hideZeroBalances) {
			return ['TON', 'NOT', 'BTC', 'CUSD', 'USDT', 'BRL', 'BNB'];
		}

		return hideZeroBalances
			? availableCurrencies.filter(
					(currency) => parseFloat(availableCurrencies[currency]) > 0 || currency === mainCurrency,
				)
			: availableCurrencies;
	};

	return (
		<>
			<CurrencyMenu currencyIcons={currenciesIconMap}>
				<CurrencyMenu.SelectedValue
					currency={mainCurrency}
					icon={currenciesIconMap.get(mainCurrency)}
					amount={
						isBalanceHidden
							? ''
							: formatNumber(displayUsd ? availableBalance * (currencyRates[mainCurrency] || 0) : availableBalance)
					}
				/>
				<CurrencyMenu.ExpandedMenu>
					<CurrencyMenu.MenuItems>
						{getVisibleCurrencies().map((currency) => (
							<CurrencyMenu.CurrencyOption
								key={currency}
								value={currency}
								icon={currenciesIconMap.get(currency)}
								amount={formatNumber(
									displayUsd ? balances[currency] * (currencyRates[mainCurrency] || 0) : balances[currency] || 0,
								)}
								onClick={onCurrencySelected}
							>
								{currency}
							</CurrencyMenu.CurrencyOption>
						))}
						<CurrencyMenu.Separator />
						<CurrencyMenu.BottomMenu>
							<CurrencyMenu.ButtonOption onClick={onBuyCryptoClicked} aria-label="Buy crypto">
								{t('global_buyCrypto')}
							</CurrencyMenu.ButtonOption>
							<CurrencyMenu.ToggleOption active={hideZeroBalances} onToggle={onHideZeroBalancesToggled}>
								Hide 0 balances
							</CurrencyMenu.ToggleOption>
							<CurrencyMenu.ToggleOption active={displayUsd} onToggle={onDisplayInUSDToggled}>
								Display in USD
							</CurrencyMenu.ToggleOption>
						</CurrencyMenu.BottomMenu>
					</CurrencyMenu.MenuItems>
				</CurrencyMenu.ExpandedMenu>
			</CurrencyMenu>
		</>
	);
};

export default CurrencyMenuComponent;
