import { sample } from 'effector';

import { domain } from './domain';
import { detectDeviceFx } from './effects';
import { appInit } from './events';
import { Device } from './types';

export const $appInitialized = domain.createStore(false);

export const $device = domain.createStore<Device>(Device.UNKNOWN);

export const initEvents = () => {
	$appInitialized.on(appInit, () => true);
	sample({
		clock: appInit,
		target: detectDeviceFx,
	});
	sample({
		clock: detectDeviceFx.doneData,
		target: $device,
	});

	appInit();
};
