import { sample } from 'effector';

import { domain } from './domain';
import { fetchStatsFx, fetchTransactionsFX } from './effects';
import { fetchStats, fetchTransactions } from './events';
import { TransactionData, Stats } from './types';

export const $stats = domain.store<Stats>({
	betsAmount: 0,
	betsCount: 0,
	wagering: 0,
	winsAmount: 0,
	winsCount: 0,
	games: [],
});

export const $transactions = domain.store<TransactionData[]>([]);

export const $transactionsCount = domain.store(0);

export const initEvents = () => {
	sample({
		clock: fetchStats,
		target: fetchStatsFx,
	});
	sample({
		clock: fetchStatsFx.doneData,
		target: $stats,
	});

	sample({
		clock: fetchTransactions,
		target: fetchTransactionsFX,
	});

	sample({
		clock: fetchTransactionsFX.doneData,
		target: $transactions,
		fn: ({ data }) => data,
	});

	sample({
		clock: fetchTransactionsFX.doneData,
		target: $transactionsCount,
		fn: ({ count }) => count,
	});
};
